import { Component, Input } from '@angular/core';
import { ToastService } from '../services/notification.service';
import { ScreenshotService } from '../services/screenshot.service';

@Component({
  selector: 'app-edit-jet-screenshot',
  templateUrl: './edit-jet-screenshot.component.html',
  styleUrls: ['./edit-jet-screenshot.component.css']
})
export class EditJetScreenshotComponent {

  constructor(
    private screenshotService: ScreenshotService,
    private toast: ToastService
  ) { }

  @Input()
  hideScreenshotButton: boolean = false;

  @Input()
  model: any = {
    balance: 700,
    currency: '₽',
    coef: 1.8,
    bid1: 740,
    coef1: '2.00',
    bid2: 680,
    coef2: '2.00',
    coefHistory1: 1,
    coefHistory2: 2,
    coefHistory3: 3,
    coefHistory4: 4,
    coefHistory5: 5,
    coefHistory6: 6,
    status: 'flying',
    pendingWidth: 45,
    win1: false,
    win1coef: 1.5,
    win1amount: 100,
    win2: false,
    win2coef: 2,
    win2amount: 200,
    scrollTop: 0,
    height: 844
  }

  captureScreenshot() {
    this.screenshotService.sendToTelegram(this.model, 'jet-screenshot')
      .subscribe(() =>
        this.toast.showToast("is-success", "Успешно отправлено в ТГ."),
        error => this.toast.showToast("is-danger", "Ошибка."));
  }
}

