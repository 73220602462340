import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

declare var BulmaTagsInput: any;

@Directive({
  selector: '[appBulmaTagsInput]'
})
export class BulmaTagsInputDirective implements OnInit {
  @Input()
  placeholder: string = '';

  @Input()
  value!: string;

  @Input()
  source!: Observable<string[]>;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    const inputElement = this.el.nativeElement;

    inputElement.classList.add('input');
    inputElement.setAttribute('type', 'text');
    inputElement.setAttribute('data-placeholder', this.placeholder);

    if (this.value) {
      inputElement.setAttribute('value', this.value);
    }

    if (this.source) {
      this.source.subscribe(tags => {
        new BulmaTagsInput(inputElement, {
          source: tags,
          caseSensitive: false
        });
      });
    } else {
      new BulmaTagsInput(inputElement);
    }
  }
}
