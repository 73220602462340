<div #screenshotContent
     class="t-content"
     (scroll)="changeScrollTop($event)"
     [ngStyle]="{
        'padding-top': screenshot.settings.showAddOrBan ? '280px' : '200px',
        'padding-bottom': screenshot.settings.showKeyboard ? '702px' : '158px'
     }">
  <div [ngClass]="{'t-client': message.from === 'client', 't-hero': message.from === 'hero'}" *ngFor="let message of screenshot.messages; let i = index" [ngStyle]="{ 'margin-bottom.px': message.from !== screenshot.messages[i+1]?.from ? 12 : 2 }">
    <!-- Only text -->
    <ng-container *ngIf="message.images.length === 0">
      <img *ngIf="screenshot.messages[i].from !== screenshot.messages[i+1]?.from" [src]="message.from === 'client' ? 'assets/images/screenshot/t-message-corner.svg' : 'assets/images/screenshot/t-right-corner.svg'" [ngClass]="{'t-message-client-corner': message.from === 'client', 't-message-hero-corner': message.from === 'hero'}" alt=""/>
      <div class="t-message" [ngClass]="{
              't-message-first': message.from !== screenshot.messages[i-1]?.from && message.from === screenshot.messages[i+1]?.from,
              't-message-middle': message.from === screenshot.messages[i-1]?.from && message.from === screenshot.messages[i+1]?.from,
              't-message-last': message.from !== screenshot.messages[i+1]?.from
          }">
        <div class="t-message-text" [innerHTML]="sanitizeAndFormatMessage(message.text)">
          <!--<span class="t-message-text-end"></span>-->
        </div>
        <div class="t-message-time-wrapper">
          <div class="t-message-time">{{message.time | date:'HH:mm'}}</div>
          <img src="assets/images/screenshot/t-icon-read.svg" class="t-message-icon-read" *ngIf="message.from === 'hero'" alt=""/>
        </div>
      </div>
    </ng-container>
    <!-- One image -->
    <ng-container *ngIf="message.images.length === 1 && !message.text.length">
      <div class="t-message t-message-screen">
        <div class="bc-screenshots-wrapper">
          <div class="bc-screenshot" [ngClass]="{
           'bc-s-single-horisontal-image': message.imageOrientation === 'h',
           'bc-s-single-vertical-image':  message.imageOrientation === 'v'
           }">
            <img [src]="message.images[0]" alt="" class="t-message-screenshot" />
            <!--<app-jet-screenshot [model]="model"></app-jet-screenshot>-->
          </div>
        </div>
        <div class="t-message-time-wrapper time-wrapper-for-screenshot">
          <div class="t-message-time">{{message.time | date:'HH:mm'}}</div>
          <img src="assets/images/screenshot/t-icon-read.svg" class="t-message-icon-read fullopacity" *ngIf="message.from === 'hero'" alt=""/>
        </div>
      </div>
    </ng-container>
    <!-- Two images -->
    <ng-container *ngIf="message.images.length === 2">
      <div class="t-message t-message-screen">
        <div class="bc-screenshots-wrapper">
          <div class="bc-screenshot" [ngClass]="{
           'bc-s-two-horisontal': message.imageOrientation === 'h',
           'bc-s-two-vert':  message.imageOrientation === 'v'
           }">
            <img [src]="message.images[0]" alt="" class="t-message-screenshot"
                 [ngClass]="{
           'bc-s-two-horisontal-first': message.imageOrientation === 'h',
           'bc-s-two-vert-first':  message.imageOrientation === 'v'
           }"/>
            <img [src]="message.images[1]" alt="" class="t-message-screenshot" [ngClass]="{
           'bc-s-two-horisontal-second': message.imageOrientation === 'h',
           'bc-s-two-vert-second':  message.imageOrientation === 'v'
           }" />
          </div>
        </div>
        <div class="t-message-time-wrapper time-wrapper-for-screenshot">
          <div class="t-message-time">{{message.time | date:'HH:mm'}}</div>
          <img
            src="assets/images/screenshot/t-icon-read.svg"
            class="t-message-icon-read fullopacity"
            *ngIf="message.from === 'hero'"
            alt="" />
        </div>
      </div>
    </ng-container>
    <!-- One image with text -->
    <ng-container *ngIf="message.images.length === 1 && message.text">
      <div class="t-message t-message-screen">
        <div class="bc-screenshots-wrapper">
          <div class="bc-screenshot" [ngClass]="{
           'bc-s-single-horisontal-image': message.imageOrientation === 'h',
           'bc-s-single-vertical-image':  message.imageOrientation === 'v'
           }">
            <img [src]="message.images[0]" alt="" class="t-message-screenshot bc-screenshot-with-caption">
          </div>
          <div class="bc-screenshot-caption">
            <div class="t-message-text" [innerHTML]="sanitizeAndFormatMessage(message.text)">
              <!--{{message.text}}
              <span class="t-message-text-end"></span>-->
            </div>
          </div>
        </div>
        <div class="t-message-time-wrapper">
          <div class="t-message-time">{{message.time | date:'HH:mm'}}</div>
          <img
            src="assets/images/screenshot/t-icon-read.svg"
            class="t-message-icon-read fullopacity"
            *ngIf="message.from === 'hero'" alt=""/>
        </div>
      </div>
    </ng-container>
  </div>
</div>
