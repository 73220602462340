import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  queueToScreenshot(sessionId: string): Observable<void> {
    return this.http.post<void>(`api/screenshot/queue/${sessionId}`, null);
  }

  sendToTelegram(screenshot: Object, page: string): Observable<Object> {
    return this.http.post("api/screenshot", { json: JSON.stringify(screenshot), page: page });
  }

  save(screenshot: Object, page: string): Observable<any> {
    return this.http.post<any>("api/screenshot/save", { json: JSON.stringify(screenshot), page: page });
  }
}

