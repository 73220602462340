import { Component } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import * as _ from 'lodash';
import { ClientService } from '../../../services/client.service';
import { ClientTableRow, TgClient } from '../../../models/telegram/tg-client.model';
import { ToastService } from '../../../services/notification.service';
import { HeroTableRow } from '../../../models/telegram/tg-hero.model';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent {

  constructor(
    private clientsService: ClientService
  ) { }

  ngOnInit() {
    this.loadClients();
  }

  currentPage: number = 1;
  itemsPerPage: number = 20;
  totalItems: number = 0;

  clients$!: Observable<ClientTableRow[]>;

  showModal: boolean = false;

  selectedClient!: TgClient;

  openModal(client?: TgClient) {

    if (client) {
      this.clientsService.getById(client.id).subscribe(client => {
        this.selectedClient = client;
      })
    }
    else {
      this.selectedClient = new TgClient("", "", "", "male", "slavic", "rus");
    }

    this.showModal = true;
  }

  saveClient() {
    if (this.selectedClient.id) {
      this.clientsService.update(this.selectedClient).subscribe(client => {
        this.showModal = false;
        this.loadClients();
      })
    }
    else {
      this.clientsService.create(this.selectedClient).subscribe(client => {
        this.showModal = false;
        this.loadClients();
      })
    }
  }

  removeClient(client: TgClient) {
    const result = window.confirm('Удалить?');
    if (result) {
      this.clientsService.delete(client.id).subscribe(() => this.loadClients());
    }
  }

  loadClients() {
    this.clients$ = this.clientsService
      .get(this.currentPage, this.itemsPerPage)
      .pipe(
        tap(response => this.totalItems = response.totalCount),
        map(response => response.items)
      );
  }

  onPageChanged(page: number) {
    this.currentPage = page;
    this.loadClients();
  }
}
