import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private jwtHelper: JwtHelperService,
    private router: Router
  ) { }

  isAuthenticated(): boolean {
    const token = localStorage.getItem("jwt");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }

    return false;
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/auth/login`, { username, password }, {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    });
  }

  logout() {
    localStorage.removeItem("jwt");
    this.router.navigate(["login"]);
  }
}
