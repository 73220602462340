import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedResult } from '../models/screen.model';
import { Timeline, TimelineView } from '../models/telegram/timeline';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {
  private readonly timelineApiUrl = `${this.baseUrl}api/timeline`;

  constructor(
    private readonly http: HttpClient,
    @Inject('BASE_URL') private readonly baseUrl: string
  ) { }

  /**
   * Fetches a paginated list of timelines.
   * @param pageNumber The page number to fetch.
   * @param pageSize The number of items per page.
   * @param query A query string for filtering the timelines.
   * @returns An observable of the paged result.
   */
  getTimelines(pageNumber: number, pageSize: number, query: string = ''): Observable<PagedResult<Timeline>> {
    const url = `${this.timelineApiUrl}?pageNumber=${pageNumber}&pageSize=${pageSize}&query=${encodeURIComponent(query)}`;
    return this.http.get<PagedResult<Timeline>>(url);
  }

  /**
   * Fetches a specific timeline by ID.
   * @param id The ID of the timeline to fetch.
   * @returns An observable of the timeline view.
   */
  getTimeline(id: string): Observable<TimelineView> {
    return this.http.get<TimelineView>(`${this.timelineApiUrl}/${id}`);
  }

  /**
   * Creates a new timeline.
   * @param timeline The timeline object to create.
   * @returns An observable of the created timeline.
   */
  createTimeline(timeline: Timeline): Observable<Timeline> {
    return this.http.post<Timeline>(this.timelineApiUrl, timeline);
  }

  /**
   * Adds sessions to a specific timeline.
   * @param timelineId The ID of the timeline.
   * @param sessionsIds An array of session IDs to add to the timeline.
   * @returns An observable representing the result of the HTTP PATCH request.
   */
  addSessionsToTimeline(timelineId: string, sessionsIds: string[]): Observable<void> {
    return this.http.patch<void>(`${this.timelineApiUrl}/${timelineId}/sessions`, sessionsIds);
  }

  removeSessionFromTimeline(sessionId: string): Observable<void> {
    const url = `${this.timelineApiUrl}/sessions/${sessionId}`;
    return this.http.delete<void>(url);
  }
}
