<ng-container *ngIf="session">

  <div class="columns mt-2" style="display: flex; align-items: center; justify-content:space-between">
    <div style="width: 100% ;max-width: 80%;">
      <div class="field" style="flex-grow: 1;">
        <label class="label">Имя сессии</label>
        <div class="control">
          <input type="text" [(ngModel)]="session.name" class="input" />
        </div>
      </div>

      <div class="field">
        <div class="control">
          <input class="input" appBulmaTagsInput placeholder="Введите теги" [source]="tags$" [value]="session.tags" (change)="onTagsChange($event)" />
        </div>
      </div>
    </div>


    <div class="buttons" style="display: flex; gap: 10px;">
      <button class="button is-info" (click)="saveSession()" title="Сохранение">
        <span class="icon is-small">
          <i class="fas fa-save" aria-hidden="true"></i>
        </span>
      </button>
      <button class="button is-success" (click)="captureScreenshot()">
        <span class="icon is-small">
          <i class="fas fa-paper-plane" aria-hidden="true"></i>
        </span>
      </button>
      <button *ngIf="session.id" class="button is-danger" (click)="removeSession()" title="Отправить в ТГ">
        <span class="icon is-small">
          <i class="fas fa-trash" aria-hidden="true"></i>
        </span>
      </button>
    </div>
  </div>
  <div style="display: flex; align-items: center;">
    <div class="tabs is-boxed pt-4" style="flex-grow: 1;">
      <ul>

        <li [class.is-active]="activeTabIndex === -1">
          <a (click)="setActiveTab(-1)">
            <span class="icon is-small"><i class="fas fa-cog" aria-hidden="true"></i></span>
            <span>Настройки</span>
          </a>
        </li>

        <li *ngFor="let screenshot of session.screenshots; let i=index" [class.is-active]="i === activeTabIndex">
          <a (click)="setActiveTab(i)">
            <span class="icon is-small">
              <i class="fas fa-image" aria-hidden="true"></i>
            </span>
            <span>{{i + 1}}</span>
            <span class="icon is-small" (click)="removeScreenshot(i)">
              <button class="delete is-small"></button>
            </span>
          </a>
        </li>

      </ul>
    </div>
    <button class="button is-warning" (click)="openSelectTemplateModal()" style="margin-left: 10px;">
      <span class="icon is-small">
        <i class="fas fa-plus-circle" aria-hidden="true"></i>
      </span>
    </button>
  </div>

  <ng-container *ngIf="activeTabIndex === -1">
    <div class="columns">
      <div class="column">
        <app-tg-settings [hideClient]="true" [settings]="session.defaultSettings" (heroChanged)="setHeroInScreenshots($event)"></app-tg-settings>
      </div>
      <div class="column">
        <div class="field has-addons">
          <div class="control">
            <div class="select">
              <select [(ngModel)]="selectedLang">
                <option *ngFor="let lang of languages" [value]="lang.value" (change)="translate()">
                  {{lang.label}}
                </option>
              </select>
            </div>
          </div>
          <div class="control">
            <button class="button is-warning"
                    (click)="translate()"
                    title="Перевести"
                    [class.is-loading]="translationInProgress"
                    [disabled]="translationInProgress">
              <span class="icon is-small">
                <i class="fas fa-language" aria-hidden="true"></i>
              </span>
              <span>
                Перевести диалоги
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngFor="let screenshot of session.screenshots; let i=index">
    <app-edit-tg-screenshot [screenshot]="screenshot" *ngIf="activeTabIndex === i">
    </app-edit-tg-screenshot>
  </ng-container>

  <app-modal title="Выбор шаблона" [showModal]="showModal" (modalOpened)="showModal=$event" [hideButtons]="true">
    <div class="columns is-multiline">
      <div class="column is-one-quarter" *ngFor="let template of templates">
        <app-screenshot [screenshot]="template" [zoom]="'30%'" (click)="addScreenshot(template)"></app-screenshot>
      </div>
    </div>
  </app-modal>

</ng-container>
