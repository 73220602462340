<div class="columns">

  <div class="column">
    <app-tg-settings [screenshot]="screenshot" [settings]="screenshot.settings" [hideHero]="true"></app-tg-settings>
  </div>

  <div class="column">
    <div class="field">
      <textarea class="textarea" [(ngModel)]="prompt" rows="5"></textarea>
    </div>

    <div class="field">
      <button class="button is-link"
              (click)="aiImprove()"
              [disabled]="isAiInProgress"
              [class.is-loading]="isAiInProgress">
        <span class="icon">
          <i class="fas fa-robot"></i>
        </span>
        <span>
          Улучшить с AI
        </span>
      </button>
    </div>

    <app-tg-messages [screenshot]="screenshot" (addAviatorImageClick)="openAviator($event)" (addLuckyJetImageClick)="openLuckyJet($event)"></app-tg-messages>
  </div>

  <div class="column">
    <app-screenshot [screenshot]="screenshot" zoom="40%"></app-screenshot>
  </div>

  <app-modal [showModal]="showAviatorModal"
             (modalOpened)="showAviatorModal=$event"
             [title]="'Авиатор'"
             [isLoading]="isLoading"
             (saveClick)="saveAviator()">

    <app-edit-aviator-screenshot [model]="aviatorModel"
                                 [hideScreenshotButton]="true">
    </app-edit-aviator-screenshot>

  </app-modal>

  <app-modal [showModal]="showLuckyJetModal"
             (modalOpened)="showLuckyJetModal=$event"
             [title]="'LuckyJet'"
             [isLoading]="isLoading"
             (saveClick)="saveJet()">

    <app-edit-jet-screenshot [model]="jetModel"
                             [hideScreenshotButton]="true">
    </app-edit-jet-screenshot>

  </app-modal>

</div>
