import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-jet-screenshot',
  templateUrl: './jet-screenshot.component.html',
  styleUrls: ['./jet-screenshot.component.css']
})
export class JetScreenshotComponent {

  constructor() {
    var localTemplate = localStorage.getItem('screenshot');
    if (localTemplate)
      this.model = JSON.parse(localTemplate);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateScrollTop();
    }, 1000);
  }

  @ViewChild('root')
  screenshotContent!: ElementRef;

  @Input()
  model: any;

  updateScrollTop(): void {
    const element = this.screenshotContent.nativeElement as HTMLElement;
    element.scrollTo(0, this.model.scrollTop);
  }

  changeScrollTop(event: any) {
    this.model.scrollTop = event.target.scrollTop;
  }

  getClassForCoefValue(value: number) {
    if (value > 2 && value <= 10) {
      return 'iKvYZC';
    } else if (value > 10) {
      return 'kCMkBP';
    }

    return 'iKEoOT';
  }
}
