<div class="t-footer">
  <div class="t-content-scroll-down" *ngIf="settings.showScrollDown">
    <img src="assets/images/screenshot/ic-scroll-down.svg">
    <div class="t-content-scroll-down-counter" *ngIf="settings.scrollCount">
      <div>{{settings.scrollCount}}</div>
    </div>
  </div>
  <img src="assets/images/screenshot/t-bottom.svg" />
</div>

<div class="t-footer-keyboard" *ngIf="settings.showKeyboard">
  <div class="t-content-scroll-down-keyboard" *ngIf="settings.showScrollDown">
    <img src="assets/images/screenshot/ic-scroll-down.svg">
    <div class="t-content-scroll-down-counter" *ngIf="settings.scrollCount">
      <div>{{settings.scrollCount}}</div>
    </div>
  </div>
  <div class="t-footer-keyboard-field">
    <img src="assets/images/screenshot/t-message-field-for-keyboard.svg" />
  </div>
  <img src="assets/images/screenshot/t-keyboard-ios.jpg" class="t-footer-keyboard-image">
</div>
