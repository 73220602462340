<div class="container">
  <table class="table mb-5 is-fullwidth">
    <tr>
      <th>Название</th>
      <th>Имена</th>
      <th>Изменен</th>
      <th>Создан</th>
      <th></th>
    </tr>

    <tr *ngFor="let hero of heroes">
      <td>
        {{hero.title}}
      </td>
      <td>
        {{hero.names}}
      </td>
      <td>
        {{hero.updatedAt  | date: 'dd/MM/yyyy'}}
      </td>
      <td>
        {{hero.createdAt | date: 'dd/MM/yyyy'}}
      </td>
      <td>
        <div class="buttons is-flex-wrap-nowrap">
          <button class="button is-small is-link" (click)="editClicked.emit(hero)">
            <span class="icon is-small">
              <i class="fas fa-edit"></i>
            </span>
          </button>
          <button class="button is-small is-danger" (click)="removeClicked.emit(hero)">
            <span class="icon is-small">
              <i class="fas fa-trash"></i>
            </span>
          </button>
        </div>
      </td>
    </tr>
  </table>
  </div>
