<div class="modal" [class.is-active]="showModal">
  <div class="modal-background" (click)="closeModal()"></div>
  <div class="modal-card" style="width: 1100px">
    <header class="modal-card-head">
      <p class="modal-card-title">{{title}}</p>
      <button class="delete" aria-label="close" (click)="closeModal()"></button>
    </header>
    <section class="modal-card-body">
      <ng-content></ng-content>
    </section>
    <footer class="modal-card-foot">
      <ng-container *ngIf="!hideButtons">
        <button class="button is-success" (click)="save()" [class.is-loading]="isLoading" [disabled]="isLoading">Сохранить</button>
        <button class="button" (click)="closeModal()" [class.is-loading]="isLoading" [disabled]="isLoading">Отменить</button>
      </ng-container>
    </footer>
  </div>
</div>
