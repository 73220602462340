import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TgScreenshot } from '../models/telegram/tg-screenshot.model';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { TgMessage } from '../models/telegram/tg-message.model';
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'app-tg-messages',
  templateUrl: './tg-messages.component.html',
  styleUrls: ['./tg-messages.component.css']
})
export class TgMessagesComponent {
  @Input()
  screenshot!: TgScreenshot;

  @Output()
  addAviatorImageClick = new EventEmitter<TgMessage>();

  @Output()
  addLuckyJetImageClick = new EventEmitter<TgMessage>();

  constructor(private translationService: TranslationService) {}

  drop(event: CdkDragDrop<any[]>) {
    // Reorder the array
    moveItemInArray(this.screenshot.messages, event.previousIndex, event.currentIndex);
  }

  addMessage(): void {
    this.screenshot.messages.push({
      from: "hero",
      text: " ",
      time: new Date(),
      images: [],
      imageOrientation: 'v'
    })
  }

  removeMessage(index: number): void {
    this.screenshot.messages.splice(index, 1);
  }

  public updateMessageTime(timeString: string, message: TgMessage): void {
    const [hours, minutes] = timeString.split(':');
    const newTime = new Date(message.time || new Date());
    newTime.setHours(parseInt(hours, 10), parseInt(minutes, 10));
    message.time = newTime;
  }

  translateMessage(message: TgMessage, targetLang: string): void {
    this.translationService.translate(message.text, targetLang).subscribe(
      (translatedText: string) => {
        message.text = translatedText;
      },
      (error: any) => {
        console.error('Ошибка перевода:', error);
      }
    );
  }
}
