<nav class="pagination is-centered mb-5" role="navigation" aria-label="pagination">
  <a class="pagination-previous" (click)="onPageChange(currentPage - 1)" [class.is-disabled]="currentPage === 1">&laquo;</a>
  <a class="pagination-next" (click)="onPageChange(currentPage + 1)" [class.is-disabled]="currentPage === totalPages">&raquo;</a>
  <ul class="pagination-list">
    <li *ngFor="let page of pages">
      <a class="pagination-link" (click)="onPageChange(page)" [class.is-current]="page === currentPage">{{ page }}</a>
    </li>
  </ul>
</nav>

