import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HeroTableRow, TgHero } from '../models/telegram/tg-hero.model';
import { PagedResult } from '../models/screen.model';
import { SelectOption } from '../models/SelectOption';

@Injectable({
  providedIn: 'root'
})
export class HeroService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL')
    private baseUrl: string
  ) { }

  get(pageNumber: number, pageSize: number): Observable<PagedResult<HeroTableRow>> {
    const params = new HttpParams()
      .set('pageNumber', pageNumber)
      .set('pageSize', pageSize);

    return this.http.get<PagedResult<HeroTableRow>>(`${this.baseUrl}api/heroes`, { params });
  }

  getSelectOptions(): Observable<SelectOption[]> {
    return this.http.get<SelectOption[]>(`${this.baseUrl}api/heroes/options`);
  }

  getById(id: string): Observable<TgHero> {
    return this.http.get<TgHero>(`${this.baseUrl}api/heroes/${id}`);
  }

  create(newHero: TgHero): Observable<TgHero> {
    return this.http.post<TgHero>(`${this.baseUrl}api/heroes`, newHero);
  }

  update(updatedHero: TgHero): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}api/heroes/${updatedHero.id}`, updatedHero);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}api/heroes/${id}`);
  }
}
