import { TgClient } from "./tg-client.model";
import { TgHero } from "./tg-hero.model";

export class TgSettings {
  constructor(
    public hero: TgHero,
    public client: TgClient,
    public unreadMessages: number,
    public battery: number,
    public network: number,
    public wiFi: number,
    public onlineStatus: string,
    public time: Date,
    public onlineStatusMinutes: number,
    public customOnlineStatus: string,
    public showAddOrBan: boolean,
    public showKeyboard: boolean,
    public showScrollDown: boolean,
    public scrollCount: number,
    public showOnlyInitials: boolean,
    public initialsBgNumber: number,
    public scrollTop: number,
    public messagingStartTime: Date,
    public maxInvervalBtwMessages: number,
    public clientGender: string,
    public clientRegion: string,
    public clientLang: string
  ) { }

  static createDefault() {
    return new TgSettings(
      new TgHero("", "", [], [], [], "uploads/default-bg.jpg"),
      new TgClient("", "vasya", "", "", "", ""),
      22, 40, 2, 2, 'online', new Date(), 11, '', true, true, true, 2, true, 1, 50, new Date(), 10, "", "", "");
  }
}
