import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMessage'
})
export class FormatMessagePipe implements PipeTransform {

  transform(value: string, ...replacements: any[]): string {
    if (!value || !replacements.length) {
      return value;
    }

    // Ensure the last argument is an object of replacements
    const replacementsObject = replacements.pop();

    if (typeof replacementsObject !== 'object') {
      return value;
    }

    // Replace wildcards with corresponding object properties
    Object.keys(replacementsObject).forEach(key => {
      value = value.replace(key, replacementsObject[key]);
    });

    return value;
  }
}
