<table class="table mb-5 is-fullwidth">
  <tr>
    <th>Имя</th>
    <th>Пол</th>
    <th>Регион</th>
    <th>Язык</th>
    <th>Создан</th>
    <th>Изменен</th>
    <th></th>
  </tr>
  <tr *ngFor="let client of clients">
    <td>
      {{client.name}}
    </td>
    <td>
      {{client.gender}}
    </td>
    <td>
      {{client.region}}
    </td>
    <td>
      {{client.language}}
    </td>
    <td>
      {{client.createdAt | date: 'dd/MM/yyyy'}}
    </td>
    <td>
      {{client.updatedAt | date: 'dd/MM/yyyy'}}
    </td>
    <td>
      <div class="buttons is-flex-wrap-nowrap">
        <button class="button is-small is-link" (click)="editClicked.emit(client)">
          <span class="icon is-small">
            <i class="fas fa-edit"></i>
          </span>
        </button>
        <button class="button is-small is-danger" (click)="removeClicked.emit(client)">
          <span class="icon is-small">
            <i class="fas fa-trash"></i>
          </span>
        </button>
      </div>
    </td>
  </tr>
</table>
