import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ToastService } from '../services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500)
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class NotificationComponent {

  constructor(public toast: ToastService) { }
 
  close(): void {
    this.toast.dismissToast();
  }
}
