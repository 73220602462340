import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { TgHero } from '../models/telegram/tg-hero.model';
import { TgScreenshot } from '../models/telegram/tg-screenshot.model';
import { TgSettings } from '../models/telegram/tg-settings.model';
import { ClientService } from '../services/client.service';
import { HeroService } from '../services/hero.service';
import { SelectOption } from '../models/SelectOption';

@Component({
  selector: 'app-tg-settings',
  templateUrl: './tg-settings.component.html',
  styleUrls: ['./tg-settings.component.css']
})
export class TgSettingsComponent {
  constructor(
    private clientService: ClientService,
    private heroService: HeroService,
  ) { }

  ngOnInit() {
    this.loadClients();
    this.heroSelectOptions$ = this.heroService.getSelectOptions().pipe(
      tap(options => {
        if (!this.settings.hero.id && options[0]) {
          this.heroService.getById(options[0].value).subscribe(h => this.settings.hero = h);
        }
      })
    );
  }

  heroSelectOptions$!: Observable<SelectOption[]>;
  clientSelectOptions$!: Observable<SelectOption[]>;

  @Output()
  heroChanged = new EventEmitter<TgHero>();

  @Input()
  settings!: TgSettings;

  @Input()
  screenshot!: TgScreenshot;

  @Input()
  hideClient: boolean = false;

  @Input()
  hideHero: boolean = false;  

  public onHeroChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const value = selectElement.value;

    this.heroService.getById(value).subscribe(hero=> {
      this.settings.hero = hero;
      this.heroChanged.emit(hero)
    })
  }

  public onClientChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const value = selectElement.value;

    this.clientService.getById(value).subscribe(client => {
      this.settings.client = client;
    })
  }

  public updateTime(timeString: string): void {
    this.updateTimeProp(timeString, this.settings.time);
  }

  public updateMessagingStartTime(timeString: string): void {
    this.updateTimeProp(timeString, this.settings.messagingStartTime);
    TgScreenshot.setMessagesTime(this.screenshot);
  }

  private updateTimeProp(timeString: string, prop: Date): void {
    const [hours, minutes] = timeString.split(':');
    prop.setHours(parseInt(hours, 10), parseInt(minutes, 10));
  }

  setMessagesTime(): void {
    TgScreenshot.setMessagesTime(this.screenshot);
  }

  loadClients() {
    this.clientSelectOptions$ = this.clientService
      .getSelectOptions(this.settings.clientGender, this.settings.clientRegion, this.settings.clientLang)
      .pipe(
        tap(options => {
          if (!this.settings.client.id && options[0]) {
            this.clientService.getById(options[0].value).subscribe(c => this.settings.client = c);
          }
        })
      );
  }

  onClientGenderChange(value: string) {
    this.settings.client.id = "";
    this.settings.clientGender = value;
    this.loadClients();
  }

  onClientRegionChange(value: string) {
    this.settings.client.id = "";
    this.settings.clientRegion = value;
    this.loadClients();
  }

  onClientLangChange(value: string) {
    this.settings.client.id = "";
    this.settings.clientLang = value;
    this.loadClients();
  }
}
