export class TgHero {
  constructor(
    public id: string,
    public title: string,
    public names: string[],
    public wins: string[],
    public events: string[],
    public backgroundUrl: string
  ) { }
}

export class HeroTableRow {
  constructor(
    public id: string,
    public title: string,
    public names: string,
    public wins: string[],
    public createdAt: Date,
    public updatedAt: Date
  ) { }
}
