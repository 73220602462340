export class TgClient {
  constructor(
    public id: string,
    public name: string,
    public avatar: string,
    public gender: string,
    public region: string,
    public language: string
  ) { }
}

export class ClientTableRow {
  constructor(
    public id: string,
    public name: string,
    public gender: string,
    public region: string,
    public language: string,
    public createdAt: Date,
    public updatedAt: Date
  ) { }
}
