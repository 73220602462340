import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
//import { HomeComponent } from './home/home.component';
//import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
//import { TabComponent } from './tab/tab.component';
//import { TabContainerComponent } from './tab-container/tab-container.component';
//import { TemplateListComponent } from './template-list/template-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ScreenshotHeaderComponent } from './screenshot/screenshot-header/screenshot-header.component';
import { ScreenshotFooterComponent } from './screenshot/screenshot-footer/screenshot-footer.component';
import { ScreenshotContentComponent } from './screenshot/screenshot-content/screenshot-content.component';
import { ScreenshotComponent } from './screenshot/screenshot.component';
import { FormatMessagePipe } from './format-message.pipe';
import { JetScreenshotComponent } from './jet-screenshot/jet-screenshot.component';
import { EditJetScreenshotComponent } from './edit-jet-screenshot/edit-jet-screenshot.component';
import { ReplaceLineBreaks } from './replace-line-breaks.pipe';
import { ClientListComponent } from './clients/components/client-list/client-list.component';
import { HeroListComponent } from './heroes/components/hero-list/hero-list.component';
import { ClientsComponent } from './clients/pages/clients/clients.component';
import { ModalComponent } from './clients/components/modal/modal.component';
import { HeroesComponent } from './heroes/pages/heroes/heroes.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthInterceptor } from './auth.interceptor';
//import { EditSessionComponent } from './session/edit-session/edit-session.component';
import { TgSettingsComponent } from './tg-settings/tg-settings.component';
import { EditTgScreenshotComponent } from './edit-tg-screenshot/edit-tg-screenshot.component';
import { TgMessagesComponent } from './tg-messages/tg-messages.component';
import { EditSessionComponent } from './session/edit-session/edit-session.component';
import { AviatorScreenshotComponent } from './aviator-screenshot/aviator-screenshot.component';
import { EditAviatorScreenshotComponent } from './edit-aviator-screenshot/edit-aviator-screenshot.component';
import { DateHttpInterceptor } from './date.interceptor';
import { NotificationComponent } from './notification/notification.component';
import { BulmaTagsInputDirective } from './bulma-tags-input.directive';
import { PagerComponent } from './pager/pager.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TimelineListComponent } from './timeline/timeline-list/timeline-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SessionListComponent } from './session/session-list/session-list.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    //HomeComponent,
    //FetchDataComponent,
    ScreenshotComponent,
    //TabComponent,
    //TabContainerComponent,
    //TemplateListComponent,
    FileUploadComponent,
    ScreenshotHeaderComponent,
    ScreenshotFooterComponent,
    ScreenshotContentComponent,
    //FormatMessagePipe,
    ReplaceLineBreaks,
    JetScreenshotComponent,
    EditJetScreenshotComponent,
    ClientListComponent,
    HeroListComponent,
    ClientsComponent,
    ModalComponent,
    HeroesComponent,
    LoginComponent,
    EditSessionComponent,
    TgSettingsComponent,
    TgMessagesComponent,
    EditTgScreenshotComponent,
    SessionListComponent,
    AviatorScreenshotComponent,
    EditAviatorScreenshotComponent,
    NotificationComponent,
    PagerComponent,
    BulmaTagsInputDirective,
    TimelineListComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    ScrollingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return '';
        }
      }
    }),
    RouterModule.forRoot([
      {
        path: '', canActivate: [AuthGuard], children: [
          { path: '', component: SessionListComponent, pathMatch: 'full' },
          { path: 'edit-jet-screenshot', component: EditJetScreenshotComponent },
          { path: 'edit-aviator-screenshot', component: EditAviatorScreenshotComponent },
          { path: 'clients', component: ClientsComponent },
          { path: 'heroes', component: HeroesComponent },
          { path: 'sessions/create', component: EditSessionComponent },
          { path: 'sessions', component: SessionListComponent },
          { path: 'sessions/edit/:id', component: EditSessionComponent },
          { path: 'timelines', component: TimelineListComponent }
        ],
      },
      { path: 'login', component: LoginComponent },
      { path: 'screenshot', component: ScreenshotComponent },
      { path: 'jet-screenshot', component: JetScreenshotComponent },
      { path: 'aviator-screenshot', component: AviatorScreenshotComponent },
    ])
  ],
  providers: [
    DatePipe,
    FormatMessagePipe,
    ReplaceLineBreaks,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
