import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { remove } from 'lodash';
import { first } from 'rxjs';

@Component({
  selector: 'app-aviator-screenshot',
  templateUrl: './aviator-screenshot.component.html',
  styleUrls: ['./aviator-screenshot.component.css']
})
export class AviatorScreenshotComponent {

  constructor() {
    var localTemplate = localStorage.getItem('screenshot');
    if (localTemplate)
      this.model = JSON.parse(localTemplate);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateScrollTop();
    }, 1000);
  }

  @ViewChild('root')
  screenshotContent!: ElementRef;

  @Input()
  model: any;

  updateScrollTop(): void {
    const element = this.screenshotContent.nativeElement as HTMLElement;
    element.scrollTo(0, this.model.scrollTop);
  }

  changeScrollTop(event: any) {
    this.model.scrollTop = event.target.scrollTop;
  }

  getColorForCoefValue(value: number) {
    if (value <= 2) {
      return '#34b4ff';
    }
    else if (value > 2 && value <= 10) {
      return '#913ef8';
    }
    else {
      return '#c017b4';
    }    
  }

  getFlightContainerId() {
    var floatCoef = parseFloat(this.model.coef);
    var id = 1;

    if (!isNaN(floatCoef)) {
      const firstDigitAfterDecimal = parseInt((floatCoef % 1 * 10).toFixed(1));

      if (floatCoef <= 2) {
        if (firstDigitAfterDecimal === 0) {
          id = 1;
        }
        else if (firstDigitAfterDecimal < 7) {
          id = firstDigitAfterDecimal;
        }
        else {
          id = 7;
        }
      }
      else if (floatCoef > 2 && floatCoef <= 10) {
        if (firstDigitAfterDecimal < 2) {
          id = 8;
        }
        else if (firstDigitAfterDecimal < 4) {
          id = 9;
        }
        else if (firstDigitAfterDecimal < 8) {
          id = 10;
        }
        else {
          id = 11;
        }
      }
      else if (floatCoef > 10) {
        if (firstDigitAfterDecimal < 2) {
          id = 12;
        }
        else if (firstDigitAfterDecimal < 4) {
          id = 13;
        }
        else if (firstDigitAfterDecimal < 8) {
          id = 14;
        }
        else {
          id = 15;
        }
      }
    }

    return id;
  }
}
