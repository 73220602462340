<nav class="navbar is-dark mb-4" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href="/">
      &nbsp;
    </a>
  </div>
  <div class="navbar-menu">
    <div class="navbar-start">
      <a class="navbar-item" routerLink="/">
        TG сессии
      </a>

      <a class="navbar-item" routerLink="/timelines">
        TG таймлайны
      </a>

      <a class="navbar-item" routerLink="/edit-jet-screenshot">
        Lucky Jet
      </a>

      <a class="navbar-item" routerLink="/edit-aviator-screenshot">
        Aviator
      </a>

      <a class="navbar-item" routerLink="/heroes">
        Герои
      </a>

      <a class="navbar-item" routerLink="/clients">
        Клиенты
      </a>
    </div>

    <div class="navbar-end">
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a class="button" (click)="logout()">
            Выйти
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
