<div id="root" #root [ngStyle]="{'height': model.height + 'px'}" (scroll)="changeScrollTop($event)">
  <div>
    <div class="main-wrapper hide-chat-bar">
      <div class="main-container">
        <div class="main-header">
          <div>
            <div class="header h-100 d-flex justify-content-between align-items-center">
              <div class="first-block h-100 d-flex align-items-center">

                <div class="game-logo h-100 ml-1 ml-md-2"></div>
                <button
                  class="btn btn-sm btn-quick-help disabled-on-game-focused shadow-none p-0 px-1 ng-star-inserted">
                  <div class="question-i d-inline-block align-middle"></div>
                  <div class="d-none d-md-inline-block align-middle pl-1">
                    How to play?
                  </div>
                </button>

              </div>
              <div class="second-block d-flex">
                <div class="d-flex h-100">
                  <div class="balance px-2 d-flex justify-content-end align-items-center">
                    <div>
                      <span class="amount font-weight-bold">{{model.balance}}</span><span
                        class="currency">&nbsp;{{model.currency}}</span>
                    </div>
                  </div>
                  <div ngbdropdown="" class="user-wrapper h-100 dropdown">
                    <div ngbdropdowntoggle=""
                      class="dropdown-toggle user w-100 h-100 px-2 d-flex align-items-center position-relative disabled-on-game-focused"
                      aria-expanded="false">
                      <div class="burger-i h-100"></div>

                      <div ngbdropdownmenu="" class="user-menu-dropdown dropdown-menu" x-placement="bottom-right">
                        <div _nghost-sgv-c46="">
                          <div class="user">
                            <div class="first-block p-2">
                              <div class="user-info">
                                <div class="avatar">
                                  <img src="static/avatars/v2/av-13.png?v=4.2.22-hotfix-2-demo"
                                    alt="static/avatars/v2/av-13.png?v=4.2.22-hotfix-2-demo">
                                </div>
                                <div class="name">d***7</div>
                              </div>
                              <div ngbdropdownitem="" class="dropdown-item avatar-change">
                                <div class="avatar-logo">
                                </div>
                                <div class="change-text">
                                  <div>
                                    Change
                                  </div>
                                  <div>
                                    Avatar
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="second-block">
                              <div class="list-menu pb-3">
                                <div class="list-menu-item">
                                  <div class="title">
                                    <div class="icon-wrapper">
                                      <div class="sound-icon"></div>
                                    </div>
                                    <span class="title-text">Sound</span>
                                  </div>
                                  <div class="switch">
                                    <div _nghost-sgv-c44="" class="ng-untouched ng-pristine ng-valid">
                                      <div class="input-switch off">
                                        <span class="oval"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="list-menu-item">
                                  <div class="title">
                                    <div class="icon-wrapper">
                                      <div class="music-icon"></div>
                                    </div>
                                    <span class="title-text">Music</span>
                                  </div>
                                  <div class="switch">
                                    <div _nghost-sgv-c44="" class="ng-untouched ng-pristine ng-valid">
                                      <div class="input-switch off">
                                        <span class="oval"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="list-menu-item">
                                  <div class="title">
                                    <div class="icon-wrapper">
                                      <div class="animation-icon"></div>
                                    </div>
                                    <span class="title-text">Animation</span>
                                  </div>
                                  <div class="switch">
                                    <div _nghost-sgv-c44="" class="ng-untouched ng-pristine ng-valid">
                                      <div class="input-switch">
                                        <span class="oval"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="list-menu">
                                <div ngbdropdownitem="" class="dropdown-item list-menu-item info-item ng-star-inserted">
                                  <div class="title">
                                    <div class="icon-wrapper">
                                      <div class="icon star-icon"></div>
                                    </div>
                                    <div class="title-text-info text-capitalize">
                                      Free bets
                                    </div>

                                  </div>
                                </div>

                                <div ngbdropdownitem="" class="dropdown-item list-menu-item info-item">
                                  <div class="title">
                                    <div class="icon-wrapper">
                                      <div class="icon fair-icon"></div>
                                    </div>
                                    <div class="title-text-info text-capitalize">
                                      Provably Fair settings
                                    </div>
                                  </div>
                                </div>
                                <div ngbdropdownitem="" class="dropdown-item list-menu-item info-item">
                                  <div class="title">
                                    <div class="icon-wrapper">
                                      <div class="icon rule-icon"></div>
                                    </div>
                                    <div class="title-text-info text-capitalize">
                                      Game rules
                                    </div>
                                  </div>
                                </div>
                                <div ngbdropdownitem="" class="dropdown-item list-menu-item info-item">
                                  <div class="title">
                                    <div class="icon-wrapper">
                                      <div class="icon history-icon"></div>
                                    </div>
                                    <div class="title-text-info text-capitalize">
                                      My bet history
                                    </div>
                                  </div>
                                </div>
                                <div ngbdropdownitem="" class="dropdown-item list-menu-item info-item">
                                  <div class="title">
                                    <div class="icon-wrapper">
                                      <div class="icon limits-icon"></div>
                                    </div>
                                    <div class="title-text-info text-capitalize">
                                      Game limits
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <a href="javascript:void(0)" class="logout ng-star-inserted">
                              <div class="home-icon"></div>
                              <span>Home</span>
                            </a>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="w-100 h-100">
          <div class="game-container">
            <div class="info-board pt-2">
              <div _nghost-sgv-c63="">
                <div class="bets-block">
                  <div class="bets-block-nav d-flex justify-content-center align-items-center my-1">
                    <div class="navigation-switcher-wrapper ng-untouched ng-valid ng-dirty" _nghost-sgv-c48="">
                      <div class="navigation-switcher">
                        <div class="slider"
                          style="transform: translate(100px); width: 100px; background-color: rgb(44, 45, 48);">
                        </div>
                        <button class="tab ng-star-inserted" style="width: 100px;">
                          Все ставки
                        </button><button class="tab ng-star-inserted active" style="width: 100px;">
                          Мои ставки
                        </button><button class="tab ng-star-inserted" style="width: 100px;">
                          Топ
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="data-list ng-star-inserted" _nghost-sgv-c57="">
                    <div class="legend px-2 d-flex align-items-center w-100">
                      <div class="date">Время</div>
                      <div class="d-flex w-100">
                        <span class="bet"> Ставка, {{model.currency}} </span><span>X</span><span class="cash-out"> Вывести, {{model.currency}} </span>
                      </div>
                      <div class="tools h-100"></div>
                    </div>
                    <div class="h-100 scroll-y">
                      <div class="ng-star-inserted" *ngFor="let bet of model.bets">
                        <div class="bet-item d-flex align-items-center" [class.celebrated]="bet.success">
                          <div class="date pl-1">
                            <div>{{bet.time}}</div>
                          </div>
                          <div class="d-flex w-100 align-items-center">
                            <div class="bet d-flex justify-content-end">
                              <div _nghost-sgv-c40="">

                                <div class="ng-star-inserted">
                                  {{bet.bid}}
                                </div>
                              </div>
                            </div>
                            <div class="multiplier-block d-flex align-items-center">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px;"
                                  [style.color]="getColorForCoefValue(bet.coef)">
                                  {{bet.coef}}x
                                </div>
                              </div>
                            </div>
                            <div *ngIf="bet.success" class="cash-out ng-star-inserted"> {{bet.bid * bet.coef | number : '1.2-2'}} </div>
                          </div>
                          <div class="d-flex align-items-center tools pr-1 disabled">
                            <div class="fairness-i disabled-on-game-focused"></div>
                            <div class="share-i ml-1"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _nghost-sgv-c64="">
                <div class="footer px-2">
                  <div class="provably-fair-block">
                    <span>This game is </span>
                    <div class="provably-fair disabled-on-game-focused">
                      <div class="i-fair"></div>
                      <span class="text-provably-fair">
                        Provably
                        Fair
                      </span>
                    </div>
                  </div>
                  <div class="logo-block disabled-on-game-focused">
                    <span>Powered by</span>
                    <a target="_blank" href="https://spribe.co">
                      <div class="i-logo"></div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="game-play">
              <div class="result-history disabled-on-game-focused my-2">
                <div _nghost-sgv-c80="">
                  <div ngbdropdown="" placement="bottom-right" class="stats dropdown">
                    <div class="payouts-wrapper">
                      <div class="payouts-block">
                        <div class="payout ng-star-inserted" _nghost-sgv-c28="">
                          <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                            style="padding: 2px 11px; border-radius: 11px;"
                            [style.color]="getColorForCoefValue(model.coefHistory1)">
                            {{model.coefHistory1}}x
                          </div>
                        </div>
                        <div class="payout ng-star-inserted" _nghost-sgv-c28="">
                          <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                            style="padding: 2px 11px; border-radius: 11px;"
                            [style.color]="getColorForCoefValue(model.coefHistory2)">
                            {{model.coefHistory2}}x
                          </div>
                        </div>
                        <div class="payout ng-star-inserted" _nghost-sgv-c28="">
                          <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                            style="padding: 2px 11px; border-radius: 11px;"
                            [style.color]="getColorForCoefValue(model.coefHistory3)">
                            {{model.coefHistory3}}x
                          </div>
                        </div>
                        <div class="payout ng-star-inserted" _nghost-sgv-c28="">
                          <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                            style="padding: 2px 11px; border-radius: 11px;"
                            [style.color]="getColorForCoefValue(model.coefHistory4)">
                            {{model.coefHistory4}}x
                          </div>
                        </div>

                        <div class="payout ng-star-inserted" _nghost-sgv-c28="">
                          <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                            style="padding: 2px 11px; border-radius: 11px;"
                            [style.color]="getColorForCoefValue(model.coefHistory5)">
                            {{model.coefHistory5}}x
                          </div>
                        </div>

                        <div class="payout ng-star-inserted" _nghost-sgv-c28="">
                          <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                            style="padding: 2px 11px; border-radius: 11px;"
                            [style.color]="getColorForCoefValue(model.coefHistory6)">
                            {{model.coefHistory6}}x
                          </div>
                        </div>

                        <div class="payout ng-star-inserted" _nghost-sgv-c28="">
                          <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                            style="padding: 2px 11px; border-radius: 11px;"
                            [style.color]="getColorForCoefValue(model.coefHistory7)">
                            {{model.coefHistory7}}x
                          </div>
                        </div>

                        <div class="payout ng-star-inserted" _nghost-sgv-c28="">
                          <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                            style="padding: 2px 11px; border-radius: 11px;"
                            [style.color]="getColorForCoefValue(model.coefHistory8)">
                            {{model.coefHistory8}}x
                          </div>
                        </div>

                        <div class="payout ng-star-inserted" _nghost-sgv-c28="">
                          <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                            style="padding: 2px 11px; border-radius: 11px;"
                            [style.color]="getColorForCoefValue(model.coefHistory8)">
                            {{model.coefHistory9}}x
                          </div>
                        </div>

                        <div class="payout ng-star-inserted" _nghost-sgv-c28="">
                          <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                            style="padding: 2px 11px; border-radius: 11px;"
                            [style.color]="getColorForCoefValue(model.coefHistory10)">
                            {{model.coefHistory10}}x
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="shadow"></div>
                    <div class="button-block">
                      <div ngbdropdowntoggle="" class="dropdown-toggle button" aria-expanded="false">
                        <div class="trigger">
                          <div class="history-icon"></div>
                          <div class="dd-icon"></div>
                        </div>
                      </div>
                    </div>
                    <div ngbdropdownmenu="" _nghost-sgv-c79="" x-placement="bottom-right" class="dropdown-menu">
                      <div class="wrapper">
                        <div class="header">
                          <div class="text text-uppercase">
                            Round
                            history
                          </div>
                        </div>
                        <div class="payouts-block">
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(145, 62, 248);">
                                  6.60x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(52, 180, 255);">
                                  1.75x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(192, 23, 180);">
                                  10.65x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(145, 62, 248);">
                                  2.15x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(192, 23, 180);">
                                  14.98x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(145, 62, 248);">
                                  2.10x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(145, 62, 248);">
                                  3.79x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(52, 180, 255);">
                                  1.37x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(145, 62, 248);">
                                  8.44x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(145, 62, 248);">
                                  2.49x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(52, 180, 255);">
                                  1.11x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(192, 23, 180);">
                                  35.32x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(145, 62, 248);">
                                  3.61x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(52, 180, 255);">
                                  1.08x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(145, 62, 248);">
                                  2.01x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(52, 180, 255);">
                                  1.35x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(145, 62, 248);">
                                  2.54x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(192, 23, 180);">
                                  52.79x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(52, 180, 255);">
                                  1.82x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(52, 180, 255);">
                                  1.19x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(52, 180, 255);">
                                  1.19x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(192, 23, 180);">
                                  10.39x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(145, 62, 248);">
                                  2.10x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(192, 23, 180);">
                                  177.79x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(52, 180, 255);">
                                  1.35x
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="payouts ng-star-inserted">
                            <div class="payout">
                              <div _nghost-sgv-c28="">
                                <div appcoloredmultiplier="" class="bubble-multiplier font-weight-bold"
                                  style="padding: 2px 11px; border-radius: 11px; color: rgb(52, 180, 255);">
                                  1.60x
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="stage-board">
                <div _nghost-sgv-c82="">
                  <div class="play-board-wrapper">
                    <div class="stage-canvas">
                      <div [class]="'flight-container-' + getFlightContainerId()">
                        <span style="color:white; font-size: large;"
                          class="flight-container-span">{{model.coef}}x</span>
                      </div>
                      <canvas width="1254" height="999"
                        style="touch-action: none; width: 418px; height: 333px; cursor: inherit;"></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bet-controls">
                <div _nghost-sgv-c86="">
                  <div class="controls controls-wrap">
                    <div class="bet-control double-bet" _nghost-sgv-c85="">
                      <div class="controls">

                        <div class="navigation ng-untouched ng-valid ng-star-inserted ng-dirty" _nghost-sgv-c48="">
                          <div class="navigation-switcher">
                            <div class="slider"
                              style="transform: translate(0px); width: 100px; background-color: rgb(44, 45, 48);">
                            </div>
                            <button class="tab ng-star-inserted active" style="width: 100px;">
                              Ставка
                            </button><button class="tab ng-star-inserted" style="width: 100px;">
                              Авто
                            </button>
                          </div>
                        </div>

                        <div class="first-row auto-game-feature">
                          <div class="bet-block">
                            <div class="spinner ng-untouched ng-dirty ng-valid" _nghost-sgv-c83="">
                              <div class="spinner big">
                                <div class="buttons">
                                  <button type="button" class="ng-star-inserted minus"></button>
                                </div>
                                <div class="input">
                                  <input [value]="model.coef1" class="font-weight-bold" type="text">
                                </div>

                                <div class="buttons">
                                  <button type="button" class="ng-star-inserted plus"></button>
                                </div>
                              </div>
                            </div>
                            <div class="bets-opt-list">
                              <button class="btn btn-secondary btn-sm bet-opt ng-star-inserted">
                                <span> 1 </span>
                              </button><button class="btn btn-secondary btn-sm bet-opt ng-star-inserted">
                                <span> 2 </span>
                              </button><button class="btn btn-secondary btn-sm bet-opt ng-star-inserted">
                                <span> 5 </span>
                              </button><button class="btn btn-secondary btn-sm bet-opt ng-star-inserted">
                                <span>
                                  10
                                </span>
                              </button>
                            </div>
                          </div>
                          <div class="buttons-block">
                            <button class="btn btn-success bet ng-star-inserted">
                              <span class="d-flex flex-column justify-content-center align-items-center">
                                <label class="label text-uppercase"> СТАВКА </label><label class="amount">
                                  <span>{{model.bid1}}</span><span class="currency">
                                    {{model.currency}}
                                  </span>
                                </label>
                              </span>
                            </button>
                          </div>
                        </div>
                        <div class="w-100 border-line d-none"></div>
                        <div class="second-row d-none">
                          <div class="auto-bet-wrapper">
                            <div class="auto-bet">
                              <button class="btn btn-sm btn-primary auto-play-btn text-uppercase ng-star-inserted">
                                Auto play
                              </button>
                            </div>
                          </div>
                          <div class="cashout-block">
                            <div class="cash-out-switcher">
                              <label class="">
                                Auto
                                Cash Out
                              </label>
                              <div _nghost-sgv-c44="" class="ng-untouched ng-pristine ng-valid">
                                <div class="input-switch off">
                                  <span class="oval"></span>
                                </div>
                              </div>
                            </div>
                            <div class="cashout-spinner-wrapper">
                              <div class="cashout-spinner disabled">
                                <div _nghost-sgv-c83="" class="ng-untouched ng-dirty">
                                  <div class="spinner small">
                                    <div class="buttons">

                                    </div>
                                    <div class="input full-width">
                                      <input class="font-weight-bold" disabled="" type="text">
                                    </div>
                                    <span class="text text-x ng-star-inserted">×</span>
                                    <div class="buttons">

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bet-control double-bet ng-star-inserted" _nghost-sgv-c85="">
                      <div class="controls">
                        <div class="sec-hand-btn close ng-star-inserted"></div>
                        <div class="navigation ng-untouched ng-valid ng-star-inserted ng-dirty">
                          <div class="navigation-switcher">
                            <div class="slider"
                              style="transform: translate(0px); width: 100px; background-color: rgb(44, 45, 48);">
                            </div>
                            <button class="tab ng-star-inserted active" style="width: 100px;">
                              Ставка
                            </button>
                            <button class="tab ng-star-inserted" style="width: 100px;">
                              Авто
                            </button>
                          </div>
                        </div>
                        <div class="first-row auto-game-feature">
                          <div class="bet-block">
                            <div class="spinner ng-untouched ng-dirty ng-valid" _nghost-sgv-c83="">
                              <div class="spinner big">
                                <div class="buttons">
                                  <button type="button" class="ng-star-inserted minus"></button>
                                </div>
                                <div class="input">
                                  <input [value]="model.coef2" class="font-weight-bold" type="text">
                                </div>
                                <div class="buttons">
                                  <button type="button" class="ng-star-inserted plus"></button>
                                </div>
                              </div>
                            </div>
                            <div class="bets-opt-list">
                              <button class="btn btn-secondary btn-sm bet-opt ng-star-inserted">
                                <span> 1 </span>
                              </button><button class="btn btn-secondary btn-sm bet-opt ng-star-inserted">
                                <span> 2 </span>
                              </button><button class="btn btn-secondary btn-sm bet-opt ng-star-inserted">
                                <span> 5 </span>
                              </button><button class="btn btn-secondary btn-sm bet-opt ng-star-inserted">
                                <span>
                                  10
                                </span>
                              </button>
                            </div>
                          </div>
                          <div class="buttons-block">
                            <button class="btn btn-success bet ng-star-inserted">
                              <span class="d-flex flex-column justify-content-center align-items-center">
                                <label class="label text-uppercase"> СТАВКА </label><label class="amount">
                                  <span>{{model.bid2}}</span><span class="currency">
                                    {{model.currency}}
                                  </span>
                                </label>
                              </span>
                            </button>
                          </div>
                        </div>
                        <div class="w-100 border-line d-none"></div>
                        <div class="second-row d-none">
                          <div class="auto-bet-wrapper">
                            <div class="auto-bet">
                              <button class="btn btn-sm btn-primary auto-play-btn text-uppercase ng-star-inserted">
                                Auto play
                              </button>
                            </div>
                          </div>
                          <div class="cashout-block">
                            <div class="cash-out-switcher">
                              <label class="">
                                Auto
                                Cash Out
                              </label>
                              <div _nghost-sgv-c44="" class="ng-untouched ng-pristine ng-valid">
                                <div class="input-switch off">
                                  <span class="oval"></span>
                                </div>
                              </div>
                            </div>
                            <div class="cashout-spinner-wrapper">
                              <div class="cashout-spinner disabled">
                                <div _nghost-sgv-c83="" class="ng-untouched ng-dirty">
                                  <div class="spinner small">
                                    <div class="buttons">
                                    </div>
                                    <div class="input full-width">
                                      <input class="font-weight-bold" disabled="" type="text">
                                    </div>
                                    <span class="text text-x ng-star-inserted">×</span>
                                    <div class="buttons">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-bar">
      </div>
    </div>
  </div>
  <div _nghost-sgv-c104="" class="ng-tns-c104-0 ng-star-inserted">
    <div class="bs-message top-center ng-tns-c104-0">
      <div class="ng-tns-c103-3 ng-tns-c104-0 ng-trigger ng-trigger-messageAnimation ng-star-inserted"
        *ngIf="model.win1">
        <div class="alert-win ng-tns-c103-3 celebrated ng-trigger ng-trigger-messageState ng-star-inserted" style="">
          <div class="multiplier ng-tns-c103-3">
            <div class="label ng-tns-c103-3">
              Вы вывели средства!
            </div>
            <div class="value ng-tns-c103-3"> {{model.win1coef}}x </div>
          </div>
          <div class="win ng-tns-c103-3 celebrated">
            <div class="label no-wrap ng-tns-c103-3">
              <span class="ng-tns-c103-3">Выигрыш</span><span class="currency ng-tns-c103-3">, {{model.currency}}</span>
            </div>
            <div class="value ng-tns-c103-3">
              <span class="ng-tns-c103-3">{{model.win1amount}}</span>
            </div>
          </div>
          <button type="button" class="close ng-tns-c103-3">
            <span class="ng-tns-c103-3">×</span>
          </button>
        </div>
      </div>
      <div class="ng-tns-c103-4 ng-tns-c104-0 ng-trigger ng-trigger-messageAnimation ng-star-inserted"
        *ngIf="model.win2">
        <div class="alert-win ng-tns-c103-4 celebrated ng-trigger ng-trigger-messageState ng-star-inserted" style="">
          <div class="multiplier ng-tns-c103-4">
            <div class="label ng-tns-c103-4">
              Вы вывели средства!
            </div>
            <div class="value ng-tns-c103-4"> {{model.win2coef}}x </div>
          </div>
          <div class="win ng-tns-c103-4 celebrated">
            <div class="label no-wrap ng-tns-c103-4">
              <span class="ng-tns-c103-4">Выигрыш</span><span class="currency ng-tns-c103-4">, {{model.currency}}</span>
            </div>
            <div class="value ng-tns-c103-4">
              <span class="ng-tns-c103-4">{{model.win2amount}}</span>
            </div>
          </div>
          <button type="button" class="close ng-tns-c103-4">
            <span class="ng-tns-c103-4">×</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="ng-tns-c105-1 ng-star-inserted">

  </div>
  <div class="lock-ui d-none"></div>
</div>
