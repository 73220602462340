import { Component, Input } from '@angular/core';
import { TgSettings } from '../../models/telegram/tg-settings.model';

@Component({
  selector: 'app-screenshot-header',
  templateUrl: './screenshot-header.component.html',
  styleUrls: ['./screenshot-header.component.css']
})
export class ScreenshotHeaderComponent {

  @Input()
  settings!: TgSettings;

  ngOnInit() {
  }

  getHours(): string {
    const hoursString = this.settings.time.getHours().toString();
    return hoursString.padStart(2, "0");
  }

  getMinutes(): string {
    const minutesString = this.settings.time.getMinutes().toString();
    return minutesString.padStart(2, "0");
  }

  getClientInitials(): string {
    var subsrings = this.settings.client.name.split(" ");
    var result = "";
    for (var i = 0; i < subsrings.length; i++) {
      const current = subsrings[i].trim();
      if (current) {
        result += current[0].toUpperCase();
      }
    }

    return result.substring(0, 2);
  }

  getRecentlyPhrase(onlineStatusMinutes: number): string {
    switch (onlineStatusMinutes) {
      case 1:
        return `был(a) ${onlineStatusMinutes} минуту назад`;
      case 2:
      case 3:
      case 4:
        return `был(a) ${onlineStatusMinutes} минуты назад`;
      default:
        return `был(a) ${onlineStatusMinutes} минут назад`;
    }
  }
}
