import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormatMessagePipe } from '../../format-message.pipe';
import { ReplaceLineBreaks } from '../../replace-line-breaks.pipe';
import emojiRegex from 'emoji-regex';
import { TgScreenshot } from '../../models/telegram/tg-screenshot.model';

@Component({
  selector: 'app-screenshot-content',
  templateUrl: './screenshot-content.component.html',
  styleUrls: ['./screenshot-content.component.css']
})
export class ScreenshotContentComponent {

  private emojiRegex: RegExp;

  private messageHTMLcache: { [key: string]: string } = {};

  constructor(
    private formatMessagePipe: FormatMessagePipe,
    private replaceLineBreaksPipe: ReplaceLineBreaks
  ) {
    this.emojiRegex = emojiRegex();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateScrollTop();
    }, 1000);
  }

  @ViewChild('screenshotContent')
  screenshotContent!: ElementRef;

  @Input()
  screenshot!: TgScreenshot;

  @Input()
  scrollTop!: number;

  @Output()
  scrollTopChange = new EventEmitter<number>();

  changeScrollTop(event: any) {
    this.screenshot.settings.scrollTop = event.target.scrollTop;
  }

  sanitizeAndFormatMessage(text: string): string {

    var hero = this.screenshot.settings.hero;
    var key = text + ":" + hero.id;

    if (this.messageHTMLcache[key])
      return this.messageHTMLcache[key];

    var heroName = this.getRandomItem(hero.names) ?? "@hero_name";
    var event = this.getRandomItem(hero.events) ?? '@event';
    var win = this.getRandomItem(hero.wins) ?? '@victory';

    var html = text.replace(
      this.emojiRegex,
      (match) => `<span class="emoji-container">${match}</span>`
    );

    html += '<span class="t-message-text-end"></span>';
    html += '&nbsp;'.repeat(12);

    html = this.formatMessagePipe.transform(html, {
      '@hero_name': heroName,
      '@event': event,
      '@victory': win
    });

    html = this.replaceLineBreaksPipe.transform(html);

    this.messageHTMLcache[key] = html;
    return html;

  }

  private updateScrollTop(): void {
    const element = this.screenshotContent.nativeElement as HTMLElement;
    element.scrollTo(0, this.screenshot.settings.scrollTop);
  }

  private getRandomItem(arr: string[]) {
    if (!arr)
      return;

    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  }

  model: any = {
    balance: 999.91,
    currency: '₽',
    coef: 1.8,
    bid1: 740,
    coef1: '2.00',
    bid2: 680,
    coef2: '2.00',
    coefHistory1: 1,
    coefHistory2: 2,
    coefHistory3: 3,
    coefHistory4: 4,
    coefHistory5: 5,
    coefHistory6: 6,
    status: 'flying',
    pendingWidth: 45,
    win1: true,
    win1coef: 1.5,
    win1amount: 100,
    win2: true,
    win2coef: 2,
    win2amount: 200,
    scrollTop: 0,
    height: 400
  }
}
