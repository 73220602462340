import { TgMessage } from "./tg-message.model";
import { TgSettings } from "./tg-settings.model";

export class TgScreenshot {
  constructor(
    public settings: TgSettings,
    public messages: TgMessage[]
  ) {
    TgScreenshot.setMessagesTime(this);
  }

  public static setMessagesTime(tgScreenshot: TgScreenshot) {
    if (tgScreenshot.messages.length === 0)
      return;

    let time = tgScreenshot.settings.messagingStartTime;
    tgScreenshot.messages[0].time = new Date(time);

    for (var i = 1; i < tgScreenshot.messages.length; i++) {
      const secondsToAdd = Math.floor(Math.random() * (tgScreenshot.settings.maxInvervalBtwMessages * 60 - 1)) + 1;
      const clonedTime = new Date(time);
      clonedTime.setTime(clonedTime.getTime() + secondsToAdd * 1000);
      tgScreenshot.messages[i].time = clonedTime;
      time = clonedTime;
    }
  }
}
