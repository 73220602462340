import { Component, Input } from '@angular/core'
import { TgScreenshot } from '../models/telegram/tg-screenshot.model';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-screenshot',
  templateUrl: './screenshot.component.html',
  styleUrls: ['./screenshot.component.css']
})
export class ScreenshotComponent {
  constructor() {
    var localScreenshot = localStorage.getItem('screenshot');
    if (localScreenshot)
      this.screenshot = JSON.parse(localScreenshot, this.reviveDate) as TgScreenshot;
  }

  @Input()
  screenshot!: TgScreenshot;

  @Input()
  zoom: string = '100%';

  private reviveDate(key: string, value: string) {
    if (typeof value !== 'string') {
      return value;
    }

    const iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
    return iso8601.test(value) ? new Date(value) : value;
  }
}

