<div class="t-header">
  <div class="t-telegram-badge">
    <img src="assets/images/screenshot/telegram-badge.svg" alt="">
  </div>
  <div class="bc-header-top">
    <div class="bc-header-top-time">
      <div><span id="spanTime">{{ getHours() }}</span></div>
      <div class="bc-header-time-divider w-embed">
        <img src="assets/images/screenshot/time-semicolon.svg" alt="" />
      </div>
      <div>
        <span id="spanMinutes">{{ getMinutes() }}</span>
      </div>
    </div>
    <div class="t-header-top-icons">
      <div class="bc-network">
        <div class="bc-network-low w-embed" *ngIf="settings.network == 1">
          <img src="assets/images/screenshot/network-low.svg" alt="" />
        </div>
        <div class="bc-network-mid w-embed" *ngIf="settings.network == 2">
          <img src="assets/images/screenshot/network-mid.svg" alt="" />
        </div>
        <div class="bc-network-high w-embed" *ngIf="settings.network == 3">
          <img src="assets/images/screenshot/network-high.svg" alt="" />
        </div>
      </div>
      <div class="bc-wifi">
        <div class="bc-wifi-low w-embed">
          <img src="assets/images/screenshot/wifi-low.svg" alt="" *ngIf="settings.wiFi == 1" />
        </div>
        <div class="bc-wifi-mid w-embed">
          <img src="assets/images/screenshot/wifi-mid.svg" alt="" *ngIf="settings.wiFi == 2" />
        </div>
        <div class="bc-wifi-high w-embed">
          <img src="assets/images/screenshot/wifi-high.svg" alt="" *ngIf="settings.wiFi == 3" />
        </div>
        <div class="bc-wifi-lte w-embed">
          <img src="assets/images/screenshot/wifi-lte.svg" alt="" *ngIf="settings.wiFi == 4" />
        </div>
        <div class="bc-wifi-5g w-embed">
          <img src="assets/images/screenshot/wifi-5g.svg" alt="" *ngIf="settings.wiFi == 5" />
        </div>
      </div>
      <div class="t-battery">
        <div class="t-battery-wrapper">
          <div class="t-battery-indicator" [style.width]="settings.battery + '%'"></div>
        </div>
        <div class="t-battery-piptik w-embed">
          <img src="assets/images/screenshot/battery-piptik.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div class="t-header-bottom">
    <div class="t-unread-wrapper">
      <img src="assets/images/screenshot/arrow-left.svg" alt="" class="t-unread-icon">
      <div class="t-unread">
        <div>{{ settings.unreadMessages }}</div>
      </div>
    </div>
    <div class="t-name-wrapper">
      <div class="t-name">{{ settings.client.name }}</div>

      <ng-container [ngSwitch]="settings.onlineStatus">
        <div *ngSwitchCase="'online'" class="t-name-online">в сети</div>
        <div *ngSwitchCase="'recently'" class="t-name-offline">был(а) недавно</div>
        <div *ngSwitchCase="'recently_time'" class="t-name-offline">{{ getRecentlyPhrase(settings.onlineStatusMinutes) }}</div>
        <div *ngSwitchCase="'custom'" class="t-name-offline">{{ settings.customOnlineStatus }}</div>
      </ng-container>
      
    </div>
    <div class="t-avatar">
      <img [src]="settings.client.avatar" alt="" *ngIf="settings.client.avatar && !settings.showOnlyInitials" class="t-avatar-image" />
      <div class="t-avatar-placeholder" *ngIf="!settings.client.avatar || settings.showOnlyInitials" [class]="'avatar-bg-' + settings.initialsBgNumber">{{getClientInitials()}}</div>
    </div>
  </div>
  <div class="t-header-addorban" *ngIf="settings.showAddOrBan">
    <div class="t-header-addorban-wrapper">
      <div class="t-addorban-ban">Заблокировать</div>
      <div class="t-addorban">Добавить</div>
    </div>
    <div class="t-header-addorban-close"><img src="assets/images/screenshot/ic-close-tg.svg" loading="lazy" alt=""></div>
  </div>
</div>
