<div class="container pt-4">

  <div class="field is-grouped is-justify-content-space-between">
    <div class="is-flex">
      <div class="field is-grouped">
        <div class="control">
          <input class="input" appBulmaTagsInput [source]="tags$" (change)="onTagsChange($event)" placeholder="Поиск по тегам">
        </div>

        <div class="field">
          <p class="control has-icons-left">
            <span class="select">
              <select style="min-width: 250px" [(ngModel)]="filterByTimelineId" (change)="onTimelineChange($event)">
                <option selected value="">Все</option>
                <option *ngFor="let timeline of timelines" [value]="timeline.id">
                  {{timeline.name}}
                </option>
              </select>
            </span>
            <span class="icon is-small is-left">
              <i class="fas fa-clock"></i>
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="is-flex">
      <div class="control mr-2">
        <button class="button is-primary" routerLink="/sessions/create">
          Создать
        </button>
      </div>
      <div class="control mr-2">
        <button class="button is-primary" (click)="showTimelinesModal=true" [disabled]="getSelectedSessionsIds().length === 0">
          Добавить в таймлайн
        </button>
      </div>
      <div class="file">
        <label class="file-label">
          <input class="file-input" type="file" (change)="onFileSelected($event)" accept=".json" />
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">Импорт</span>
          </span>
        </label>
      </div>
    </div>
  </div>

  <table class="table is-hoverable is-fullwidth">
    <thead>
      <tr>
        <th></th>
        <th>Имя</th>
        <th>Теги</th>
        <th>Кол-во скриншотов</th>
        <th>Таймлайн</th>
        <th>Изменен</th>
        <th>Создан</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let session of sessions" class="is-narrow">
        <td>
          <label class="checkbox" *ngIf="!session.timelineId">
            <input type="checkbox" [(ngModel)]="session.isChecked" />
          </label>
        </td>
        <td>
          <a [routerLink]="'/sessions/edit/' + session.id">{{session.name || '_'}}</a>
        </td>
        <td>
          <div class="tags">
            <span *ngFor="let tag of session.tags" class="tag is-link">{{tag}}</span>
          </div>
        </td>
        <td>
          {{session.screenshotsCount}}
        </td>
        <td>
          <span *ngIf="session.timelineId" class="tag is-success">{{session.timelineName}}</span>
        </td>
        <td>
          {{session.updatedAt  | date: 'dd/MM/yyyy'}}
        </td>
        <td>
          {{session.createdAt | date: 'dd/MM/yyyy'}}
        </td>
        <td>
          <div class="buttons is-flex-wrap-nowrap">
            <button class="button is-small is-info" (click)="duplicate(session.id)">
              <span class="icon is-small">
                <i class="fas fa-copy"></i>
              </span>
            </button>
            <button class="button is-small is-success" (click)="export(session)">
              <span class="icon is-small">
                <i class="fas fa-file-export"></i>
              </span>
            </button>
            <button class="button is-small is-danger" *ngIf="session.timelineId" (click)="removeSessionFromTimeline(session.id)">
              <span class="icon is-small">
                <i class="fas fa-clock"></i>
              </span>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <app-pager [totalItems]="totalItems"
             [itemsPerPage]="itemsPerPage"
             [currentPage]="currentPage"
             (pageChanged)="onPageChanged($event)">
  </app-pager>

  <app-modal
             title="Выбор таймлайна"
             [showModal]="showTimelinesModal"
             (modalOpened)="showTimelinesModal=$event"
             (saveClick)="addSessionsToTimeline()"
             >
    <div class="control select align-content-center">
      <select style="min-width: 300px" [(ngModel)]="selectedTimelineId">
        <option *ngFor="let timeline of timelines" [value]="timeline.id">
          {{timeline.name}}
        </option>
      </select>
    </div>
  </app-modal>

</div>
