import { Component, Input } from '@angular/core';
import { ToastService } from '../services/notification.service';
import { ScreenshotService } from '../services/screenshot.service';

@Component({
  selector: 'app-edit-aviator-screenshot',
  templateUrl: './edit-aviator-screenshot.component.html',
  styleUrls: ['./edit-aviator-screenshot.component.css']
})
export class EditAviatorScreenshotComponent {
  constructor(
    private screenshotService: ScreenshotService,
    private toast: ToastService
  ) { }

  @Input()
  hideScreenshotButton: boolean = false;

  @Input()
  model: any = {
    balance: 999.91,
    currency: 'USD',
    coef: 1.8,
    bid1: 740,
    coef1: '2.00',
    bid2: 680,
    coef2: '2.00',
    coefHistory1: 1,
    coefHistory2: 2,
    coefHistory3: 3,
    coefHistory4: 4,
    coefHistory5: 5,
    coefHistory6: 6,
    coefHistory7: 7,
    coefHistory8: 8,
    coefHistory9: 9,
    coefHistory10: 10,
    status: 'flying',
    pendingWidth: 45,
    win1: true,
    win1coef: 1.5,
    win1amount: 100,
    win2: true,
    win2coef: 2,
    win2amount: 200,
    scrollTop: 0,
    height: 844,
    bets: [
      { time: '19:30', bid: 100, coef: 1.2, success: true },
      { time: '19:30', bid: 50, coef: 1.9, success: false }
    ]
  }

  newBet: any = {};

  addBet() {
    const clone = JSON.parse(JSON.stringify(this.newBet));
    this.model.bets.push(clone);
    this.newBet = {};
  }

  captureScreenshot() {
    this.screenshotService.sendToTelegram(this.model, 'aviator-screenshot')
      .subscribe(() =>
        this.toast.showToast("is-success", "Успешно отправлено в ТГ."),
        error => this.toast.showToast("is-danger", "Ошибка."));
  }
}
