import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeroTableRow, TgHero } from '../../../models/telegram/tg-hero.model';

@Component({
  selector: 'app-hero-list',
  templateUrl: './hero-list.component.html',
  styleUrls: ['./hero-list.component.css']
})
export class HeroListComponent {
  @Input()
  heroes: HeroTableRow[] | null = [];

  @Output()
  editClicked = new EventEmitter<any>();

  @Output()
  removeClicked = new EventEmitter<any>();
}
