<div class="columns">
  <div class="column">
    <div class="form">

      <div class="field is-grouped">
        <div class="control is-expanded">
          <div class="field">
            <label class="label">Баланс</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.balance" name="balance">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">Валюта</label>
            <div class="control">
              <div class="select">
                <select [(ngModel)]="model.currency" name="currency">
                  <option value="RUB">RUB</option>
                  <option value="UZS">UZS</option>
                  <option value="KZT">KZT</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="AZN">AZN</option>
                  <option value="UAH">UAH</option>
                  <option value="INR">INR</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">Коэфициент</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coef" name="coef">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control is-expanded">
          <div class="field">
            <label class="label">Ист. 1</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory1" name="coefHistory1">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">2</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory2" name="coefHistory2">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">3</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory3" name="coefHistory3">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">4</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory4" name="coefHistory4">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">5</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory5" name="coefHistory5">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-grouped">

        <div class="control is-expanded">
          <div class="field">
            <label class="label">6</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory6" name="coefHistory6">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">7</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory7" name="coefHistory7">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">6</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory8" name="coefHistory8">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">9</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory9" name="coefHistory9">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">10</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory10" name="coefHistory10">
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Высота</label>
        <div class="control">
          <input class="input" type="text" [(ngModel)]="model.height" name="height">
        </div>
      </div>

      <div class="field is-grouped" *ngIf="!hideScreenshotButton">
        <div class="control">
          <button class="button is-link" (click)="captureScreenshot()">Сделать скриншот</button>
        </div>
      </div>

    </div>
  </div>

  <div class="column">
    <div class="form">
      <div class="field is-grouped">
        <div class="control">
          <div class="field">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="model.win1" name="win1">
                Победа #1
              </label>
            </div>
          </div>
        </div>
        <div class="control">
          <div class="field">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="model.win2" name="win2">
                Победа #2
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal" *ngIf="model.win1">
        <div class="field-label">
          <label class="label">Победа #1</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.win1coef" name="win1coef" placeholder="Коэфициент">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.win1amount" name="win1amount" placeholder="Сумма">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal" *ngIf="model.win2">
        <div class="field-label">
          <label class="label">Победа #2</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.win2coef" name="win2coef" placeholder="Коэфициент">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.win2amount" name="win2amount" placeholder="Сумма">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Ставка #1</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coef1" name="coef1" placeholder="Коэфициент">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.bid1" name="bid1" placeholder="Сумма">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Ставка #2</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coef2" name="coef2" placeholder="Коэфициент">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.bid2" name="bid2" placeholder="Сумма">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal" *ngFor="let bet of model.bets">
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="bet.time" name="newBet.time" placeholder="Время">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="bet.bid" name="newBet.bid" placeholder="Сумма">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="bet.coef" name="newBet.coef" placeholder="Коэфициент">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="bet.success" name="newBet.success">
                Успех
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="newBet.time" name="newBet.time" placeholder="Время">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="newBet.bid" name="newBet.bid" placeholder="Сумма">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="newBet.coef" name="newBet.coef" placeholder="Коэфициент">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="newBet.success" name="newBet.success">
                Успех
              </label>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <button class="button is-success" (click)="addBet()">Добавить ставку</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="column">
    <app-aviator-screenshot [model]="model"></app-aviator-screenshot>
  </div>
</div>
