<div class="field is-grouped is-justify-content-end">
  <p class="control">
    <button class="button is-primary" (click)="openModal()">Создать героя</button>
  </p>
</div>

<app-hero-list (editClicked)="openModal($event)"
               (removeClicked)="removeHero($event)"
               [heroes]="heroes$ | async" />

<app-pager [totalItems]="totalItems"
           [itemsPerPage]="itemsPerPage"
           [currentPage]="currentPage"
           (pageChanged)="onPageChanged($event)">
</app-pager>

<app-modal [showModal]="showModal"
           (modalOpened)="showModal=$event"
           [title]="'Создать/редактировать героя'"
           (saveClick)="saveHero()">

  <form class="form" [formGroup]="heroForm">

    <div class="field">
      <label class="label">Название</label>
      <div class="control">
        <input type="text" class="input" [class.is-danger]="(title.invalid && (title.dirty || title.touched)) && title.errors?.required" formControlName="title" />
      </div>
    </div>

    <label class="label">Имена</label>
    <ng-container formArrayName="names">
      <div class="field has-addons" *ngFor="let name of names.controls; let i = index">
        <div class="control">
          <input class="input" [class.is-danger]="(name.invalid && (name.dirty || name.touched)) && name.errors?.required" type="text" formControlName="{{i}}">
          <p class="help is-danger" *ngIf="(name.invalid && (name.dirty || name.touched)) && name.errors?.required">
            Введите имя героя
          </p>
        </div>
        <div class="control">
          <a class="button is-danger" (click)="removeName(i)">
            Удалить
          </a>
        </div>
      </div>
    </ng-container>

    <div class="field has-addons">
      <div class="control">
        <a class="button is-info" (click)="addName('')">
          Добавить
        </a>
        <p class="help is-danger" *ngIf="names.errors?.atLeastOneError">
          Добавьте минимум одно имя героя
        </p>
      </div>
    </div>


    <label class="label">Фразы "С победой!"</label>
    <ng-container formArrayName="wins">
      <div class="field has-addons" *ngFor="let win of wins.controls; let i = index">
        <div class="control">
          <input class="input" [class.is-danger]="(win.invalid && (win.dirty || win.touched)) && win.errors?.required" type="text" formControlName="{{i}}">
          <p class="help is-danger" *ngIf="(win.invalid && (win.dirty || win.touched)) && win.errors?.required">
            Введите фразу
          </p>
        </div>
        <div class="control">
          <a class="button is-danger" (click)="removeWin(i)">
            Удалить
          </a>
        </div>
      </div>
    </ng-container>

    <div class="field has-addons">
      <div class="control">
        <a class="button is-info" (click)="addWin('')">
          Добавить
        </a>
        <p class="help is-danger" *ngIf="wins.errors?.atLeastOneError">
          Добавьте минимум одну фразу героя
        </p>
      </div>
    </div>

    <label class="label">События</label>
    <ng-container formArrayName="events">
      <div class="field has-addons" *ngFor="let event of events.controls; let i = index">
        <div class="control">
          <input class="input" [class.is-danger]="(event.invalid && (event.dirty || event.touched)) && event.errors?.required" type="text" formControlName="{{i}}">
          <p class="help is-danger" *ngIf="(event.invalid && (event.dirty || event.touched)) && event.errors?.required">
            Введите событие
          </p>
        </div>
        <div class="control">
          <a class="button is-danger" (click)="removeEvent(i)">
            Удалить
          </a>
        </div>
      </div>
    </ng-container>

    <div class="field has-addons">
      <div class="control">
        <a class="button is-info" (click)="addEvent('')">
          Добавить
        </a>
        <p class="help is-danger" *ngIf="events.errors?.atLeastOneError">
          Добавьте минимум одно событие
        </p>
      </div>
    </div>

    <div class="field">
      <label class="label">Фон</label>
      <div class="control">
        <app-file-upload [files]="backgroundUrl.value ? [backgroundUrl.value] : []"
                         [max]="1"
                         [single]="true"
                         (filesUpdated)="backgroundUrl.setValue($event[0]); this.backgroundUrl.markAsTouched();"
                         [hide2buttons]="true" />

        <p class="help is-danger mt-2" *ngIf="(backgroundUrl.invalid && (backgroundUrl.dirty || backgroundUrl.touched)) && backgroundUrl.errors?.required">
          Добавьте фон
        </p>
      </div>
    </div>
  </form>
</app-modal>
