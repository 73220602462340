<ng-container>

  <div class="field is-grouped">
    <div class="file mr-3">
      <label class="file-label">
        <input class="file-input" type="file" (change)="onFileSelected($event)" [disabled]="!single && files.length === max">
        <span class="file-cta" style="background-color: #fff">
          <span class="file-icon">
            <i class="fas fa-upload"></i>
          </span>
          <span class="file-label">
            Загрузить
          </span>
        </span>
      </label>
    </div>
    <div class="control" *ngIf="!hide2buttons">
      <button class="button" (click)="addLuckyJetImageClick.emit()" [disabled]="!single && files.length === max">
        <span class="icon is-small">
          <i class="fas fas fa-rocket"></i>
        </span>
        <span>LuckyJet</span>
      </button>
    </div>
    <div class="control" *ngIf="!hide2buttons">
      <button class="button" (click)="addAviatorImageClick.emit()" [disabled]="!single && files.length === max">
        <span class="icon is-small">
          <i class="fas fas fa-plane"></i>
        </span>
        <span>Aviator</span>
      </button>
    </div>
  </div>

  <p class="subtitle is-6 mt-3 mb-2" *ngIf="files.length">Изображения</p>

  <div class="columns is-multiline">
    <div class="column is-narrow" *ngFor="let file of files; let i = index">
      <div class="image-container" style="position: relative;">
        <figure class="image is-square is-64x64">
          <img [src]="file" />
        </figure>
        <span class="icon is-small delete" style="position: absolute; top: 2px; right: 2px;" (click)="removeImage(i)">
          <i class="fas fa-music" aria-hidden="true"></i>
        </span>
      </div>
    </div>
  </div>
</ng-container>
