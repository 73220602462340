<div class="columns">
  <div class="column">
    <div class="form">

      <div class="field is-grouped">
        <div class="control is-expanded">
          <div class="field">
            <label class="label">Баланс</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.balance" name="balance">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">Валюта</label>
            <div class="control">
              <div class="select">
                <select [(ngModel)]="model.currency" name="currency">
                  <option value="₽">₽</option>
                  <option value="₴">₴</option>
                  <option value="$">$</option>
                  <option value="€">€</option>
                  <option value="₸">₸</option>
                  <option value="₼">₼</option>
                  <option value="S">S</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">Коэфициент</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coef" name="coef">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control is-expanded">
          <div class="field">
            <label class="label">1</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory1" name="coefHistory1">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">2</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory2" name="coefHistory2">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">3</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory3" name="coefHistory3">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">4</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory4" name="coefHistory4">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">5</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory5" name="coefHistory5">
            </div>
          </div>
        </div>
        <div class="control is-expanded">
          <div class="field">
            <label class="label">6</label>
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coefHistory6" name="coefHistory6">
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Высота</label>
        <div class="control">
          <input class="input" type="text" [(ngModel)]="model.height" name="height">
        </div>
      </div>

    </div>
  </div>

  <div class="column">
    <div class="form">

      <div class="field is-grouped">
        <div class="control is-expanded">
          <div class="field">
            <label class="label">Статус</label>
            <div class="control">
              <div class="select">
                <select [(ngModel)]="model.status" name="status">
                  <option value="flying">Летит</option>
                  <option value="pending">Ожидание</option>
                  <option value="gone">Улетел</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="control is-expanded" *ngIf="model.status == 'pending'">
          <div class="field">
            <label class="label">Ширина ожидания</label>
            <div class="control">
              <input type="range" [(ngModel)]="model.pendingWidth" name="pendingWidth" min="0" max="100" />
            </div>
          </div>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <div class="field">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="model.win1" name="win1">
                Победа #1
              </label>
            </div>
          </div>
        </div>
        <div class="control">
          <div class="field">
            <div class="control">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="model.win2" name="win2">
                Победа #2
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal" *ngIf="model.win1">
        <div class="field-label">
          <label class="label">Победа #1</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.win1coef" name="win1coef" placeholder="Коэфициент">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.win1amount" name="win1amount" placeholder="Сумма">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal" *ngIf="model.win2">
        <div class="field-label">
          <label class="label">Победа #2</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.win2coef" name="win2coef" placeholder="Коэфициент">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.win2amount" name="win2amount" placeholder="Сумма">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Ставка #1</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coef1" name="coef1" placeholder="Коэфициент">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.bid1" name="bid1" placeholder="Сумма">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Ставка #2</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.coef2" name="coef2" placeholder="Коэфициент">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input" type="text" [(ngModel)]="model.bid2" name="bid2" placeholder="Сумма">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-grouped" *ngIf="!hideScreenshotButton">
        <div class="control">
          <button class="button is-link" (click)="captureScreenshot()">Сделать скриншот</button>
        </div>
      </div>
    </div>
  </div>

  <div class="column">
    <app-jet-screenshot [model]="model"></app-jet-screenshot>
  </div>
</div>
