import { TgScreenshot } from "./tg-screenshot.model";
import { TgSettings } from "./tg-settings.model";

export class TgSession {
  constructor(
    public id: string,
    public name: string,
    public tags: string,
    public defaultSettings: TgSettings,
    public screenshots: TgScreenshot[],
    public createdAt: Date,
    public updatedAt: Date,
    public timelineId: string | null
  ) { }
}
