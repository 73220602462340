import { Component } from '@angular/core';
import { Observable, map, startWith, tap } from 'rxjs';
import { TgSessionService } from '../../services/tg-session.service';
import { TgSession } from '../../models/telegram/tg-session.model';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ToastService } from '../../services/notification.service';
import { TimelineService } from '../../services/timeline.service';
import { Timeline } from '../../models/telegram/timeline';

@Component({
  selector: 'app-session-list',
  templateUrl: './session-list.component.html',
  styleUrls: ['./session-list.component.css']
})
export class SessionListComponent {
  constructor(
    private tgSessionService: TgSessionService,
    private timelineService: TimelineService,
    private toast: ToastService,
  ) {
  }

  sessions!: any[];
  timelines!: Timeline[];
  tags$!: Observable<string[]>;
  currentPage: number = 1;
  selectedTags: string = '';
  selectedTimelineId: string = '';
  filterByTimelineId: string = '';

  itemsPerPage: number = 20;
  totalItems: number = 0;
  showTimelinesModal: boolean = false;

  ngOnInit() {
    this.loadSessions();
    this.tags$ = this.tgSessionService.getTags();

    this.timelineService
      .getTimelines(1, 5000, '')
      .pipe(
        map(response => response.items)
      ).subscribe(result => {
        if (result.length > 0) {
          this.selectedTimelineId = result[0].id;
        }
        this.timelines = result;
      });
  }

  loadSessions() {
    this.tgSessionService
      .get(this.currentPage, this.itemsPerPage, this.selectedTags, this.filterByTimelineId)
      .pipe(
        tap(response => this.totalItems = response.totalCount),
        map(response => response.items)
      ).subscribe(result => this.sessions = result);
  }

  onPageChanged(page: number) {
    this.currentPage = page;
    this.loadSessions();
  }

  onTagsChange(event: any) {
    this.selectedTags = event.target.value;
    this.currentPage = 1;
    this.loadSessions();
  }

  onTimelineChange(event: any) {
    this.filterByTimelineId = event.target.value;
    this.currentPage = 1;
    this.loadSessions();
  }

  export(session: TgSession) {
    this.tgSessionService.export(session.id).subscribe(
      (blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = session.name + '.json';
        a.click();
        window.URL.revokeObjectURL(url);
      },
      error => this.toast.showToast("is-danger", "Ошибка экспортирования")
    )
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      var file = input.files[0];

      this.tgSessionService.import(file).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress && event.total) {
            //this.progress = Math.round((100 * event.loaded) / event.total);
          } else if (event instanceof HttpResponse) {
            this.currentPage = 1;
            this.loadSessions();
            this.toast.showToast("is-success", "Сессия успешно импортирована");
          }
        },
        error => this.toast.showToast("is-danger", "Ошибка импортирования")
      );
    }
  }

  duplicate(id: string) {
    this.tgSessionService.duplicate(id).subscribe(
      () => {
        this.currentPage = 1;
        this.loadSessions();
        this.toast.showToast("is-success", "Сессия успешно скопирована");
      },
      error => this.toast.showToast("is-danger", "Ошибка копирования")
    );
  }

  addSessionsToTimeline() {

    var selectedSessions = this.getSelectedSessionsIds();

    this.timelineService
      .addSessionsToTimeline(this.selectedTimelineId, selectedSessions)
      .subscribe(() => {
        this.showTimelinesModal = false;
        this.loadSessions();        
        this.toast.showToast("is-success", `Сессии успешно добавлены в таймлайн`);
      },
        error => this.toast.showToast("is-danger", "Ошибка")
      );
  }

  getSelectedSessionsIds() {
    var selectedSessionsIds = this.sessions
      .filter(session => session.isChecked)
      .map(session => session.id);

    return selectedSessionsIds;
  }

  removeSessionFromTimeline(id: string) {
    this.timelineService
      .removeSessionFromTimeline(id)
      .subscribe(() => {
        this.loadSessions();
        this.toast.showToast("is-success", `Сессиия успешно удалена из таймлайна`);
      },
        error => this.toast.showToast("is-danger", "Ошибка")
      );
  }
}
