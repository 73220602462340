import { Component } from '@angular/core';
import { TgScreenshot } from '../../models/telegram/tg-screenshot.model';
import { TgSession } from '../../models/telegram/tg-session.model';
import { TgSettings } from '../../models/telegram/tg-settings.model';
import { ScreenshotService } from '../../services/screenshot.service';
import { TemplateService } from '../../services/template.service';
import * as _ from 'lodash';
import { TgSessionService } from '../../services/tg-session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../services/notification.service';
import { TgHero } from '../../models/telegram/tg-hero.model';
import { Observable } from 'rxjs';
import { Call } from '@angular/compiler';

@Component({
  selector: 'app-edit-session',
  templateUrl: './edit-session.component.html',
  styleUrls: ['./edit-session.component.css']
})
export class EditSessionComponent {

  constructor(
    private templateService: TemplateService,
    private tgSessionService: TgSessionService,
    private screenshotService: ScreenshotService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    var id = this.route.snapshot.params['id'];
    if (id) {
      this.tgSessionService.getById(id)
        .subscribe(session => {
          return this.session = session;
        })
    } else {
      this.session = new TgSession('', '', '', TgSettings.createDefault(), [], new Date(), new Date(), null);
    }

    this.tags$ = this.tgSessionService.getTags();
  }

  showModal: boolean = false;

  activeTabIndex: number = -1;

  setActiveTab(index: number) {
    this.activeTabIndex = index;
  }

  templates: TgScreenshot[] = [];

  tags$!: Observable<string[]>;

  session!: TgSession;

  languages: any[] = [
    { label: "Английский", value: "en-GB" },
    { label: "Русский", value: "RU" },
    { label: "Испанский", value: "ES" },
    { label: "Польский", value: "PL" },
    { label: "Немецкий", value: "DE" }
  ]

  openSelectTemplateModal() {
    this.templateService.get(4).subscribe(templates => {
      this.templates = [];
      for (var i = 0; i < templates.length; i++) {
        var settings = _.cloneDeep(this.session.defaultSettings);
        this.templates.push(new TgScreenshot(settings, templates[i].messages));
      }
      this.showModal = true;
    })
  }

  setHeroInScreenshots(hero: TgHero) {
    this.session.screenshots.forEach(screenshot => screenshot.settings.hero = hero);
  }

  addScreenshot(screenshot: TgScreenshot) {
    this.session.screenshots.push(screenshot);
    this.showModal = false;
  }

  removeScreenshot(index: number) {
    const result = window.confirm('Удалить?');
    if (result) {
      this.session.screenshots.splice(index, 1);
    }
  }

  saveSession(callback?: Function) {
    if (!this.session.id) {
      this.tgSessionService.create(this.session)
        .subscribe(
          (session) => {
            this.session.id = session.id;
            if (callback) callback();
            return this.toast.showToast("is-success", "Сессия успешно создана.");
          },
          error => this.toast.showToast("is-danger", "Ошибка")
        );
    }
    else {
      this.tgSessionService.update(this.session)
        .subscribe(
          () => {
            if (callback) callback();
            return this.toast.showToast("is-success", "Сессия успешно изменена.");
          },
          error => this.toast.showToast("is-danger", "Ошибка")
        );
    }
  }

  removeSession() {
    const result = window.confirm('Удалить?');
    if (result) {
      this.tgSessionService.delete(this.session.id).subscribe(() => this.router.navigate(['/sessions']));
    }
  }

  captureScreenshot() {
    this.saveSession(() => {
      this.screenshotService.queueToScreenshot(this.session.id).subscribe(() =>
        this.toast.showToast("is-success", "Задание добавлено в очередь, ожидайте скриншоты в ТГ канале"),
        error => this.toast.showToast("is-danger", "Ошибка"));
    });
  }

  onTagsChange(event: any) {
    this.session.tags = event.target.value;
  }

  selectedLang: string = 'en-GB';
  translationInProgress = false;
  translate() {
    this.translationInProgress = true;
    this.tgSessionService
      .translate(this.session, this.selectedLang)
      .subscribe(
        (translatedSession) => {
          this.toast.showToast("is-success", "Сессия успешно переведена");
          this.session = translatedSession;
          this.translationInProgress = false;
        },
        error => this.toast.showToast("is-danger", "Ошибка перевода")
      )
  }
}
