import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TgSession } from '../models/telegram/tg-session.model';
import { PagedResult } from '../models/screen.model';
import { TgMessage } from '../models/telegram/tg-message.model';


@Injectable({
  providedIn: 'root'
})
export class TgSessionService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  get(pageNumber: number, pageSize: number, tags: string, timelineId: string): Observable<PagedResult<any>> {
    const params = new HttpParams()
      .set('pageNumber', pageNumber)
      .set('pageSize', pageSize)
      .set('tags', tags)
      .set('timelineId', timelineId);

    return this.http.get<PagedResult<any>>(`${this.baseUrl}api/session`, { params });
  }

  getById(id: string): Observable<TgSession> {
    return this.http.get<TgSession>(`${this.baseUrl}api/session/${id}`);
  }

  create(newTgSession: TgSession): Observable<TgSession> {
    return this.http.post<TgSession>(`${this.baseUrl}api/session`, newTgSession);
  }

  update(updatedTgSession: TgSession): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}api/session/${updatedTgSession.id}`, updatedTgSession);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}api/session/${id}`);
  }

  getTags(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}api/session/tags`);
  }

  export(id: string): Observable<Blob> {
    return this.http.get(`${this.baseUrl}api/session/export/${id}`, { responseType: 'blob' });
  }

  import(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(`${this.baseUrl}api/session/import`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  duplicate(id: string): Observable<any> {
    return this.http.post(`${this.baseUrl}api/session/duplicate/${id}`, {});
  }

  translate(session: TgSession, lang: string): Observable<TgSession> {
    return this.http.post<TgSession>(`${this.baseUrl}api/session/translate/${lang}`, session);
  }

  aiImprove(dialog: TgMessage[], prompt: string): Observable<TgMessage[]> {
    return this.http.post<TgMessage[]>(`${this.baseUrl}api/session/ai-improve`, { dialog: dialog, prompt: prompt });
  }
}
