<div #root id="root" style="width: 390px; height: 844px; position: relative; overflow: scroll;"  [ngStyle]="{'height': model.height + 'px'}" (scroll)="changeScrollTop($event)">
  <div id="mobile" class="sc-iiNlcH kBTrOQ">
    <div class="sc-lhlUkk aBbyI wincontent" id="wincontent1" style="opacity: 1; transform: translateY(0px);" *ngIf="model.win1">
      <div id="win-notify" class="sc-kImNAt hkGOCL">
        <div class="sc-hOzowv wrJpk">
          <span>Вы успели забрать!</span>
          <div class="wincontentkef">
            <h2>
              x<div class="value" style="display: inline;">{{model.win1coef}}</div>
            </h2>
          </div>
        </div>
        <div class="sc-dsHJmm jmsckl">
          <div class="wincontentmoney">
            <h2>
              <div class="value" style="display: inline;">{{model.win1amount}}</div> {{model.currency}}
            </h2>
          </div>
          <span>Ваш выигрыш</span>
        </div>
      </div>
    </div>
    <div class="sc-lhlUkk aBbyI wincontent" id="wincontent2" style="opacity: 1; transform: translateY(70px);" *ngIf="model.win2">
      <div id="win-notify" class="sc-kImNAt hkGOCL">
        <div class="sc-hOzowv wrJpk">
          <span>Вы успели забрать!</span>
          <div class="wincontentkef">
            <h2>
              x<div class="value" style="display: inline;">{{model.win2coef}}</div>
            </h2>
          </div>
        </div>
        <div class="sc-dsHJmm jmsckl">
          <div class="wincontentmoney">
            <h2>
              <div class="value" style="display: inline;">{{model.win2amount }}</div> {{model.currency}}
            </h2>
          </div>
          <span>Ваш выигрыш</span>
        </div>
      </div>
    </div>
    <div class="sc-jeToga ftrNPE">
      <div class="sc-cqQeAO kvGivG">
        <img src="assets/images/jet-screenshot/logo.svg" class="sc-gJqSRm ghQGUg">
        <div class="sc-iFoMEM jfvgzu sc-jOferD heNcmO">
          <div class="sc-bTTELM hKTHjj">
            <div class="sc-bYMpWt jcoBZb sc-evzXkX sc-jWgTtR sc-eDZJfc jjBbeA cIzVem dNqjyF">
              <button id="open-rules-button" class="sc-ezOQGI gcvbyP">
                <div class="sc-gikAfH eBrSsH">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 10C3 13.864 6.136 17 10 17C13.864 17 17 13.864 17 10C17 6.136 13.864 3 10 3C6.136 3 3 6.136 3 10ZM9.125 14.375V14.025C9.125 13.6384 9.4384 13.325 9.825 13.325H10.175C10.5616 13.325 10.875 13.6384 10.875 14.025V14.375C10.875 14.7616 10.5616 15.075 10.175 15.075H9.825C9.4384 15.075 9.125 14.7616 9.125 14.375ZM11.519 10.119C11.1443 10.4989 10.8741 10.8285 10.7601 11.404C10.7548 11.4305 10.7498 11.461 10.7451 11.4956C10.6973 11.842 10.4013 12.1 10.0516 12.1H10C9.62979 12.1 9.32968 11.7999 9.32968 11.4297C9.32968 11.4107 9.33048 11.3917 9.33212 11.3728C9.33793 11.3045 9.34425 11.2528 9.35105 11.2177C9.45933 10.6599 9.73403 10.1593 10.119 9.769L10.987 8.887C11.246 8.635 11.4 8.285 11.4 7.9C11.4 7.13 10.77 6.5 10 6.5C9.51072 6.5 9.07797 6.75437 8.82747 7.1374C8.78315 7.20516 8.74311 7.29596 8.70735 7.4098C8.6157 7.70152 8.34531 7.9 8.03953 7.9H7.9C7.56989 7.9 7.30228 7.63239 7.30228 7.30228C7.30228 7.25785 7.30724 7.21356 7.31707 7.17023C7.3594 6.98335 7.40744 6.8323 7.4612 6.71706C7.90685 5.7617 8.8758 5.1 10 5.1C11.547 5.1 12.8 6.353 12.8 7.9C12.8 8.516 12.548 9.076 12.149 9.475L11.519 10.119Z"></path>
                  </svg>
                </div>
              </button>
            </div>
            <div class="sc-bYMpWt jcoBZb sc-evzXkX sc-EJAja jjBbeA jGycXN">
              <button class="sc-ezOQGI gcvbyP">
                <div class="sc-gikAfH eBrSsH">
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4975 7.46273H14.5334C13.154 7.46516 12.0365 8.55469 12.0341 9.89962C12.0322 11.2476 13.1509 12.342 14.5334 12.3438H17.5V12.5579C17.5 14.9093 16.0746 16.2997 13.6623 16.2997H7.33898C4.92612 16.2997 3.50067 14.9093 3.50067 12.5579V7.43664C3.50067 5.08529 4.92612 3.70032 7.33898 3.70032H13.6598C16.0721 3.70032 17.4975 5.08529 17.4975 7.43664V7.46273ZM6.81758 7.45771H10.7654H10.7679H10.7729C11.0684 7.4565 11.3073 7.22172 11.3061 6.93296C11.3048 6.64481 11.0634 6.41185 10.7679 6.41307H6.81758C6.52391 6.41428 6.28561 6.64663 6.28436 6.93357C6.28312 7.22172 6.52204 7.4565 6.81758 7.45771Z"></path>
                    <path opacity="0.4" d="M13.3267 10.2076C13.473 10.8734 14.0568 11.3418 14.7232 11.3297H16.9981C17.2754 11.3297 17.5003 11.1 17.5003 10.8162V9.04415C17.4997 8.7609 17.2754 8.53064 16.9981 8.53003H14.6697C13.9116 8.53247 13.2993 9.16171 13.3005 9.93716C13.3005 10.0279 13.3094 10.1187 13.3267 10.2076Z"></path>
                    <ellipse cx="14.7002" cy="9.9297" rx="0.699968" ry="0.699967"></ellipse>
                  </svg>
                  <div class="sc-fWQKxP dozcwg">
                    <div style="display: inline;" id="showmoneybalance">{{model.balance}}</div> {{model.currency}}
                  </div>
                </div>
              </button>
            </div>
            <div class="sc-bYMpWt jcoBZb sc-evzXkX sc-jWgTtR jjBbeA cIzVem">
              <button id="open-menu-button" class="sc-ezOQGI gcvbyP">
                <div class="sc-gikAfH eBrSsH">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M12.8527 3.00098H15.2227C16.2043 3.00098 16.9996 3.80303 16.9996 4.79286V7.18292C16.9996 8.17275 16.2043 8.9748 15.2227 8.9748H12.8527C11.8711 8.9748 11.0758 8.17275 11.0758 7.18292V4.79286C11.0758 3.80303 11.8711 3.00098 12.8527 3.00098Z"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.77687 3H7.14692C8.12846 3 8.9238 3.80205 8.9238 4.79188V7.18195C8.9238 8.17177 8.12846 8.97383 7.14692 8.97383H4.77687C3.79534 8.97383 3 8.17177 3 7.18195V4.79188C3 3.80205 3.79534 3 4.77687 3ZM4.77787 11.0262H7.14792C8.12946 11.0262 8.92479 11.8283 8.92479 12.8181V15.2082C8.92479 16.1973 8.12946 17.0001 7.14792 17.0001H4.77787C3.79633 17.0001 3.00099 16.1973 3.00099 15.2082V12.8181C3.00099 11.8283 3.79633 11.0262 4.77787 11.0262ZM15.2227 11.0262H12.8527C11.8711 11.0262 11.0758 11.8283 11.0758 12.8181V15.2082C11.0758 16.1973 11.8711 17.0001 12.8527 17.0001H15.2227C16.2043 17.0001 16.9996 16.1973 16.9996 15.2082V12.8181C16.9996 11.8283 16.2043 11.0262 15.2227 11.0262Z"></path>
                  </svg>
                </div>
              </button>
            </div>
            <div class="sc-bYMpWt jcoBZb sc-evzXkX sc-jWgTtR jjBbeA cIzVem">
              <button id="open-chat-mobile-button" class="sc-ezOQGI gcvbyP">
                <div class="sc-gikAfH eBrSsH">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.81002 3.22656C10.7869 3.22656 11.7196 3.39046 12.5732 3.6882C12.2149 4.2473 12.0077 4.91144 12.0077 5.62399C12.0077 7.61008 13.6177 9.22012 15.6038 9.22012C16.1581 9.22012 16.6831 9.09472 17.152 8.87074C17.185 9.11561 17.2021 9.36592 17.2021 9.61969C17.2021 13.1505 13.8925 16.0128 9.81002 16.0128C9.1117 16.0128 8.436 15.9291 7.7956 15.7726C6.95692 16.5193 5.55687 17.1386 3.59678 17.6299C3.51221 17.6511 3.42275 17.6393 3.34663 17.5968C3.18151 17.5046 3.12238 17.296 3.21455 17.1309L3.35704 16.8718C3.9865 15.7105 4.36351 14.7713 4.48805 14.0543C3.20589 12.9061 2.41797 11.3425 2.41797 9.61969C2.41797 6.08886 5.7275 3.22656 9.81002 3.22656Z" fill-opacity="0.5"></path>
                    <path opacity="0.4" d="M18.0014 5.62399C18.0014 4.29993 16.928 3.22656 15.604 3.22656C14.2799 3.22656 13.2065 4.29993 13.2065 5.62399C13.2065 6.94805 14.2799 8.02141 15.604 8.02141C16.928 8.02141 18.0014 6.94805 18.0014 5.62399Z" fill-opacity="0.5"></path>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="sc-iAEawV bEIVYh sc-iQAVnG kKKKp">
        <div class="sc-eeMvmM bdrNoi">
          <div class="sc-ilhmMj" [class]="getClassForCoefValue(model.coefHistory1)" id="historykef1">{{model.coefHistory1}}x</div>
          <div [class]="getClassForCoefValue(model.coefHistory2)" id="historykef2">{{model.coefHistory2}}x</div>
          <div [class]="getClassForCoefValue(model.coefHistory3)" id="historykef3">{{model.coefHistory3}}x</div>
          <div [class]="getClassForCoefValue(model.coefHistory4)" id="historykef4">{{model.coefHistory4}}x</div>
          <div [class]="getClassForCoefValue(model.coefHistory5)" id="historykef5">{{model.coefHistory5}}x</div>
          <div [class]="getClassForCoefValue(model.coefHistory6)" id="historykef6">{{model.coefHistory6}}x</div>
        </div>
        <div class="sc-dPWrhe cHnpTB"></div>
        <div class="sc-jOiSOi hZXvaT">
          <div class="sc-bYMpWt jcoBZb sc-kMjNwy ehicgt">
            <button class="sc-ezOQGI gcvbyP">
              <div class="sc-gikAfH eBrSsH">
                <img src="assets/images/jet-screenshot/time.svg">
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="sc-eYulFz bPchqO" id="selectcontent1" *ngIf="model.status === 'flying'">
        <div class="sc-iJnaPW gJfITN">
          <div style="opacity: 1; transition: all 0.32s ease 0s;">
            <div class="sc-fnGiBr gpBElz">
              <div class="sc-fEXmlR kmMxzM"></div>
              <div class="sc-bjfHbI eESzqg"></div>
              <div class="sc-pyfCe gdEtBc"></div>
              <div class="sc-pyfCe bFzIYk"></div>
              <div class="sc-eDWCr iiOtUt">
                <div id="manrunning" style="position: absolute; width: 110px; opacity: 1; transform: translate(233.292px, 57.0395px);">
                  <div class="sc-jrcTuL hlENrR">
                    <img src="assets/images/jet-screenshot/fire.svg" class="gCduHI">
                    <img src="assets/images/jet-screenshot/yellow.png" class="sc-ipEyDJ gVfFVi">
                    <img src="assets/images/jet-screenshot/man.png" class="sc-csuSiG kFJbVE">
                  </div>
                </div>
                <svg style="width: 358px; height: 196px;">
                  <defs>
                    <linearGradient id="grad" x1="0" x2="1" y1="0" y2="1">
                      <stop stop-color="#9d7aff" stop-opacity=".33"></stop>
                      <stop offset=".987" stop-color="#9d7aff" stop-opacity="0"></stop>
                    </linearGradient>
                    <linearGradient id="grad_stroke" x1="0" x2="1" y1="0" y2="1">
                      <stop stop-color="#9D7AFF"></stop>
                      <stop offset=".787" stop-color="#622BFC"></stop>
                      <stop offset="1" stop-color="#5c24fc" stop-opacity="0"></stop>
                    </linearGradient>
                  </defs>
                  <g>
                    <path d="M 0 196 Q 178.86108177176743 196 268.29162265765115 122.03947979625549" fill="transparent" stroke="url(#grad_stroke)"></path>
                    <path d="M 0 196 Q 178.86108177176743 196 268.29162265765115 122.03947979625549 L 268.29162265765115 196 Z" fill="url(#grad)"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div class="sc-dmctIk Gmsrf">
              <div class="sc-dIfARi ctnJTj">
                <div id="showkefcurrent" style="display: inline;">{{model.coef}}</div>
              </div>
              <div class="sc-hHTYSt cGwmxa">Улетел</div>
            </div>
          </div>
        </div>
      </div>
      <div class="sc-eYulFz bPchqO" id="selectcontent2" *ngIf="model.status === 'pending'">
        <div class="sc-iJnaPW gJfITN">
          <div style="opacity: 0; transition: all 0.32s ease 0s;">
            <div class="sc-fnGiBr gpBElz">
              <div class="sc-fEXmlR kmMxzM"></div>
              <div class="sc-bjfHbI eESzqg"></div>
              <div class="sc-pyfCe kPWvfR"></div>
              <div class="sc-pyfCe fivlhN"></div>
              <div class="sc-eDWCr iiOtUt">
                <div style="position: absolute; width: 110px; opacity: 0; transform: translate(-20px, 96px);">
                  <div class="sc-jrcTuL keHHWF">
                    <img src="assets/images/jet-screenshot/yellow.png" class="sc-ipEyDJ gVfFVi">
                    <img src="assets/images/jet-screenshot/yellow.png" class="sc-csuSiG kFJbVE">
                  </div>
                </div>
                <svg style="width: 358px; height: 196px;">
                  <defs>
                    <linearGradient id="grad" x1="0" x2="1" y1="0" y2="1">
                      <stop stop-color="#9d7aff" stop-opacity=".33"></stop>
                      <stop offset=".987" stop-color="#9d7aff" stop-opacity="0"></stop>
                    </linearGradient>
                    <linearGradient id="grad_stroke" x1="0" x2="1" y1="0" y2="1">
                      <stop stop-color="#9D7AFF"></stop>
                      <stop offset=".787" stop-color="#622BFC"></stop>
                      <stop offset="1" stop-color="#5c24fc" stop-opacity="0"></stop>
                    </linearGradient>
                  </defs>
                  <g>
                    <path d="M 0 196 Q 0 196 0 196" fill="transparent" stroke="url(#grad_stroke)"></path>
                    <path d="M 0 196 Q 0 196 0 196 L 0 196 Z" fill="url(#grad)"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div class="sc-dmctIk hMkSje">
              <div class="sc-dIfARi ctnJTj">
                <div id="showkefcurrent" style="display: inline;">{{model.coef}}</div>
              </div>
              <div class="sc-hHTYSt cGwmxa">Улетел</div>
            </div>
          </div>
          <div class="sc-bBABsx egEzUl">
            <img src="assets/images/jet-screenshot/loader.svg">
            <div class="sc-iveFHk jkDJLO">Ожидание следующего раунда</div>
            <div class="sc-kgTSHT llAwyz">
              <div class="sc-fLcnxK evTgHM" [ngStyle]="{'width': model.pendingWidth + '%'}" id="targetWidthRange"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="sc-eYulFz bPchqO" id="selectcontent3" *ngIf="model.status === 'gone'">
        <div class="sc-iJnaPW gJfITN">
          <div style="opacity: 1; transition: all 0.32s ease 0s;">
            <div class="sc-fnGiBr gpBElz">
              <div class="sc-fEXmlR kmMxzM"></div>
              <div class="sc-bjfHbI eESzqg"></div>
              <div class="sc-pyfCe kPWvfR"></div>
              <div class="sc-pyfCe fivlhN"></div>
              <div class="sc-eDWCr iiOtUt">
                <svg style="width: 358px; height: 196px;">
                  <defs>
                    <linearGradient id="grad" x1="0" x2="1" y1="0" y2="1">
                      <stop stop-color="#9d7aff" stop-opacity=".33"></stop>
                      <stop offset=".987" stop-color="#9d7aff" stop-opacity="0"></stop>
                    </linearGradient>
                    <linearGradient id="grad_stroke" x1="0" x2="1" y1="0" y2="1">
                      <stop stop-color="#9D7AFF"></stop>
                      <stop offset=".787" stop-color="#622BFC"></stop>
                      <stop offset="1" stop-color="#5c24fc" stop-opacity="0"></stop>
                    </linearGradient>
                  </defs>
                  <g>
                    <path d="M 0 196 Q 183.9577080325768 196 275.9365620488652 122.03957180736533" fill="transparent" stroke="url(#grad_stroke)"></path>
                    <path d="M 0 196 Q 183.9577080325768 196 275.9365620488652 122.03957180736533 L 275.9365620488652 196 Z" fill="url(#grad)"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div class="sc-dmctIk cIbJUh">
              <div class="sc-dIfARi ctnJTj">
                <div id="showkefcurrent" style="display: inline;">{{model.coef}}</div>
              </div>
              <div class="sc-hHTYSt cGwmxa">Улетел</div>
            </div>
          </div>
        </div>
      </div>
      <div class="sc-hlLBRy geoHoQ sc-cTVMo fqEwAc">
        <div class="sc-fbYMXx jfXasf">
          <div class="sc-GhhNo bQggaw">
            <div class="sc-bCfvAP cPjVak">
              <div value="false" class="sc-cOxWqc kvirpg"></div>
              <div class="sc-AHaJN ja-Daee">Автоставка</div>
            </div>
            <div class="sc-bCfvAP gLivqB" disabled="">
              <div value="false" class="sc-cOxWqc kvirpg"></div>
              <div class="sc-AHaJN ja-Daee">Автовывод</div>
            </div>
            <div class="sc-khsqcC cVMPIH">
              <input id="coef-input-1" pattern="\d*(\s|\d)*(\.|,)?\d?\d?" type="tel" class="sc-hTBuwn gUqxak" [value]="model.coef1" readonly>
              <div class="sc-jfTVlA SUYlp">
                <div>x</div>
                <div>{{model.coef1}}</div>
              </div>
            </div>
          </div>
          <div class="sc-fXqpFg hnofHu">
            <div class="sc-oZIhv tDOoc">
              <div class="sc-ehvNnt gpIwAm">
                <div class="sc-bYMpWt jcoBZb sc-hiDMwi egMhQy">
                  <button class="sc-ezOQGI gcvbyP">
                    <div class="sc-gikAfH eBrSsH">
                      <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiBmaWxsPSJub25lIi8+CjxwYXRoIGQ9Ik0xLjA4MzI4IDdIMTAuOTE2N0MxMS40OTY4IDcgMTIgNi41NDQzIDEyIDZDMTIgNS40NTU3IDExLjQ5NjggNSAxMC45MTY3IDVIMS4wODMyOEMwLjUzMTE1OSA1IDAgNS40NTU3IDAgNkMwIDYuNTQ0MyAwLjUzMTE1OSA3IDEuMDgzMjggN1oiIGZpbGw9IiM5NDhBQzUiLz4KPC9zdmc+Cg==">
                    </div>
                  </button>
                </div>
                <div class="sc-eJDSGI dBESSU">
                  <input id="bet-amount-mobile-input-1" type="tel" class="sc-jfvxQR jnpevu" [value]="model.bid1" readonly>
                </div>
                <div class="sc-bYMpWt jcoBZb sc-hiDMwi egMhQy">
                  <button class="sc-ezOQGI gcvbyP">
                    <div class="sc-gikAfH eBrSsH">
                      <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMDAzNDkgMTJDNi42MDQ1NCAxMiA3LjEwNzc1IDExLjQ4MTMgNy4xMDc3NSAxMC45MjhWNy4wODkzNEgxMC45MTY3QzExLjQ5NjggNy4wODkzNCAxMiA2LjU5MTM1IDEyIDUuOTk2NTRDMTIgNS40MDE3MyAxMS40OTY4IDQuOTAzNzUgMTAuOTE2NyA0LjkwMzc1SDcuMTA3NzVWMS4wNjUxM0M3LjEwNzc1IDAuNDkxMDY2IDYuNjA0NTQgMCA2LjAwMzQ5IDBDNS40MDI0NSAwIDQuODk5MjQgMC40OTEwNjYgNC44OTkyNCAxLjA2NTEzVjQuOTAzNzVIMS4wODMyOEMwLjUzMTE1OSA0LjkwMzc1IDAgNS40MDE3MyAwIDUuOTk2NTRDMCA2LjU5MTM1IDAuNTMxMTU5IDcuMDg5MzQgMS4wODMyOCA3LjA4OTM0SDQuODk5MjRWMTAuOTI4QzQuODk5MjQgMTEuNDgxMyA1LjQwMjQ1IDEyIDYuMDAzNDkgMTJaIiBmaWxsPSIjOTQ4QUM1Ii8+Cjwvc3ZnPgo=">
                    </div>
                  </button>
                </div>
              </div>
              <div class="sc-laZRCg GmqJm">
                <div class="sc-bYMpWt jcoBZb sc-hiDMwi egMhQy">
                  <button class="sc-ezOQGI gcvbyP">
                    <div class="sc-gikAfH eBrSsH">+50</div>
                  </button>
                </div>
                <div class="sc-bYMpWt jcoBZb sc-hiDMwi egMhQy">
                  <button class="sc-ezOQGI gcvbyP">
                    <div class="sc-gikAfH eBrSsH">+100</div>
                  </button>
                </div>
                <div class="sc-bYMpWt jcoBZb sc-hiDMwi egMhQy">
                  <button class="sc-ezOQGI gcvbyP">
                    <div class="sc-gikAfH eBrSsH">+200</div>
                  </button>
                </div>
                <div class="sc-bYMpWt jcoBZb sc-hiDMwi egMhQy">
                  <button class="sc-ezOQGI gcvbyP">
                    <div class="sc-gikAfH eBrSsH">+500</div>
                  </button>
                </div>
              </div>
            </div>
            <button id="make-bet-button" type="bet" class="sc-gGvHcT hpuDCm">
              <div type="bet" class="sc-ckEbSK lkUJoH">
                <div>Ставка</div>
              </div>
            </button>
          </div>
        </div>
        <div class="sc-fbYMXx jfXasf">
          <div class="sc-GhhNo bQggaw">
            <div class="sc-bCfvAP cPjVak">
              <div value="false" class="sc-cOxWqc kvirpg"></div>
              <div class="sc-AHaJN ja-Daee">Автоставка</div>
            </div>
            <div class="sc-bCfvAP gLivqB" disabled="">
              <div value="false" class="sc-cOxWqc kvirpg"></div>
              <div class="sc-AHaJN ja-Daee">Автовывод</div>
            </div>
            <div class="sc-khsqcC cVMPIH">
              <input id="coef-input-2" pattern="\d*(\s|\d)*(\.|,)?\d?\d?" type="tel" class="sc-hTBuwn gUqxak" [value]="model.coef2" readonly>
              <div class="sc-jfTVlA SUYlp">
                <div>x</div>
                <div>{{model.coef2}}</div>
              </div>
            </div>
          </div>
          <div class="sc-fXqpFg hnofHu">
            <div class="sc-oZIhv tDOoc">
              <div class="sc-ehvNnt gpIwAm">
                <div class="sc-bYMpWt jcoBZb sc-hiDMwi egMhQy">
                  <button class="sc-ezOQGI gcvbyP">
                    <div class="sc-gikAfH eBrSsH">
                      <img data-savepage-src="https://lucky-jet.1play.one/assets/media/97de90559589bee034295a9d2e9e626a.svg" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiBmaWxsPSJub25lIi8+CjxwYXRoIGQ9Ik0xLjA4MzI4IDdIMTAuOTE2N0MxMS40OTY4IDcgMTIgNi41NDQzIDEyIDZDMTIgNS40NTU3IDExLjQ5NjggNSAxMC45MTY3IDVIMS4wODMyOEMwLjUzMTE1OSA1IDAgNS40NTU3IDAgNkMwIDYuNTQ0MyAwLjUzMTE1OSA3IDEuMDgzMjggN1oiIGZpbGw9IiM5NDhBQzUiLz4KPC9zdmc+Cg==">
                    </div>
                  </button>
                </div>
                <div class="sc-eJDSGI dBESSU">
                  <input id="bet-amount-mobile-input-2" type="tel" class="sc-jfvxQR jnpevu" [value]="model.bid2" readonly>
                </div>
                <div class="sc-bYMpWt jcoBZb sc-hiDMwi egMhQy">
                  <button class="sc-ezOQGI gcvbyP">
                    <div class="sc-gikAfH eBrSsH">
                      <img data-savepage-src="https://lucky-jet.1play.one/assets/media/02f73e3c8eee420b71b6f7c6b409b20d.svg" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMDAzNDkgMTJDNi42MDQ1NCAxMiA3LjEwNzc1IDExLjQ4MTMgNy4xMDc3NSAxMC45MjhWNy4wODkzNEgxMC45MTY3QzExLjQ5NjggNy4wODkzNCAxMiA2LjU5MTM1IDEyIDUuOTk2NTRDMTIgNS40MDE3MyAxMS40OTY4IDQuOTAzNzUgMTAuOTE2NyA0LjkwMzc1SDcuMTA3NzVWMS4wNjUxM0M3LjEwNzc1IDAuNDkxMDY2IDYuNjA0NTQgMCA2LjAwMzQ5IDBDNS40MDI0NSAwIDQuODk5MjQgMC40OTEwNjYgNC44OTkyNCAxLjA2NTEzVjQuOTAzNzVIMS4wODMyOEMwLjUzMTE1OSA0LjkwMzc1IDAgNS40MDE3MyAwIDUuOTk2NTRDMCA2LjU5MTM1IDAuNTMxMTU5IDcuMDg5MzQgMS4wODMyOCA3LjA4OTM0SDQuODk5MjRWMTAuOTI4QzQuODk5MjQgMTEuNDgxMyA1LjQwMjQ1IDEyIDYuMDAzNDkgMTJaIiBmaWxsPSIjOTQ4QUM1Ii8+Cjwvc3ZnPgo=">
                    </div>
                  </button>
                </div>
              </div>
              <div class="sc-laZRCg GmqJm">
                <div class="sc-bYMpWt jcoBZb sc-hiDMwi egMhQy">
                  <button class="sc-ezOQGI gcvbyP">
                    <div class="sc-gikAfH eBrSsH">+50</div>
                  </button>
                </div>
                <div class="sc-bYMpWt jcoBZb sc-hiDMwi egMhQy">
                  <button class="sc-ezOQGI gcvbyP">
                    <div class="sc-gikAfH eBrSsH">+100</div>
                  </button>
                </div>
                <div class="sc-bYMpWt jcoBZb sc-hiDMwi egMhQy">
                  <button class="sc-ezOQGI gcvbyP">
                    <div class="sc-gikAfH eBrSsH">+200</div>
                  </button>
                </div>
                <div class="sc-bYMpWt jcoBZb sc-hiDMwi egMhQy">
                  <button class="sc-ezOQGI gcvbyP">
                    <div class="sc-gikAfH eBrSsH">+500</div>
                  </button>
                </div>
              </div>
            </div>
            <button id="make-bet-button" type="bet" class="sc-gGvHcT hpuDCm">
              <div type="bet" class="sc-ckEbSK lkUJoH">
                <div>Ставка</div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="sc-eSEOys dnRuLA">
        <div id="tabs-switcher" class="sc-bWOGAC eLUJnJ sc-ayeQl kOQoTL">
          <div class="sc-eGugkK htdxJn">
            <div class="sc-brePNt cDuXgV active">Все</div>
            <div class="sc-brePNt bBTKZr">Мои</div>
            <div class="sc-brePNt bBTKZr">Топ</div>
            <div class="sc-bYMpWt jcoBZb sc-csDkEv hwxqIM">
              <button class="sc-ezOQGI gcvbyP">
                <div class="sc-gikAfH eBrSsH">
                  <div class="sc-dEVLtI dAwLqR"></div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="sc-nTrUm ksmtpr">
          <div class="sc-dkBdza fLChFa">
            <div class="sc-WKhSL jdYzio">
              <span>Всего ставок:</span>
              <span id="bets-total">458</span>
            </div>
            <div class="sc-bYMpWt jcoBZb sc-evzXkX sc-ikHGee jjBbeA iKFhVI">
              <button id="prev-round-button" class="sc-ezOQGI gcvbyP">
                <div class="sc-gikAfH eBrSsH">
                  <img data-savepage-src="https://lucky-jet.1play.one/assets/media/3dcd3911867699236ae66d057a566025.svg" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjgxMjI2IDguMDAwMjRDMS44MTIyNiA0LjU4MjcxIDQuNTgyMjIgMS44MTI3NCA3Ljk5OTc2IDEuODEyNzRDMTEuNDE3MyAxLjgxMjc0IDE0LjE4NzMgNC41ODI3MSAxNC4xODczIDguMDAwMjRDMTQuMTg3MyAxMS40MTc4IDExLjQxNzMgMTQuMTg3NyA3Ljk5OTc2IDE0LjE4NzdDNC41ODIyMiAxNC4xODc3IDEuODEyMjYgMTEuNDE3OCAxLjgxMjI2IDguMDAwMjRaTTcuOTk5NzYgMC4zMTI3NDRDMy43NTM3OSAwLjMxMjc0NCAwLjMxMjI1NiAzLjc1NDI4IDAuMzEyMjU2IDguMDAwMjRDMC4zMTIyNTYgMTIuMjQ2MiAzLjc1Mzc5IDE1LjY4NzcgNy45OTk3NiAxNS42ODc3QzEyLjI0NTcgMTUuNjg3NyAxNS42ODczIDEyLjI0NjIgMTUuNjg3MyA4LjAwMDI0QzE1LjY4NzMgMy43NTQyOCAxMi4yNDU3IDAuMzEyNzQ0IDcuOTk5NzYgMC4zMTI3NDRaTTguNDk1ODUgNC44ODUwMUM4LjQ5NTg1IDQuNDcwOCA4LjE2MDA2IDQuMTM1MDEgNy43NDU4NSA0LjEzNTAxQzcuMzMxNjQgNC4xMzUwMSA2Ljk5NTg1IDQuNDcwOCA2Ljk5NTg1IDQuODg1MDFWOC41MjAyNkM2Ljk5NTg1IDguNzg0MzkgNy4xMzQ3OCA5LjAyOTA0IDcuMzYxNjEgOS4xNjQzNkwxMC4xODkxIDEwLjg1MTFDMTAuNTQ0OCAxMS4wNjMzIDExLjAwNTIgMTAuOTQ3IDExLjIxNzQgMTAuNTkxMkMxMS40Mjk3IDEwLjIzNTUgMTEuMzEzMyA5Ljc3NTEyIDEwLjk1NzYgOS41NjI5MUw4LjQ5NTg1IDguMDk0MzZWNC44ODUwMVoiIGZpbGw9IiNERkU1RjIiIGZpbGwtb3BhY2l0eT0iMC42Ii8+Cjwvc3ZnPgo=">
                  <span class="sc-uhnfH ldOHdj">Пред. раунд</span>
                </div>
              </button>
            </div>
          </div>
          <div style="position: relative;">
            <div style="overflow: visible; height: 0px; width: 0px;">
              <div aria-label="grid" aria-readonly="true" class="ReactVirtualized__Grid ReactVirtualized__List" role="grid" tabindex="0" style="box-sizing: border-box; direction: ltr; height: 442px; position: relative; width: 352px; will-change: transform; overflow: hidden auto;">
                <div class="ReactVirtualized__Grid__innerScrollContainer" role="rowgroup" style="width: auto; height: 1290px; max-width: 352px; max-height: 1290px; overflow: hidden; position: relative;">
                  <div style="height: 43px; left: 0px; position: absolute; top: 0px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjNWI2MmEwIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICDQmNCSCiAgICAgICAgPC90ZXh0PgogICAgICA8L3N2Zz4KICAgIA==&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">Иван</p>
                      <div class="sc-gelMue guczSY sc-gBInCZ oxmqh" size="8">10 000.00 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 43px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjNWI2MmEwIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICDQmNCSCiAgICAgICAgPC90ZXh0PgogICAgICA8L3N2Zz4KICAgIA==&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">Иван</p>
                      <div class="sc-gelMue guczSY sc-gBInCZ oxmqh" size="8">10 000.00 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 86px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjYzEyYmI2IiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICBTVAogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">STCgbd</p>
                      <div class="sc-gelMue guczSY sc-gBInCZ oxmqh" size="8">10 000.00 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 129px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjYzEyYmI2IiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICBTVAogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">STCgbd</p>
                      <div class="sc-gelMue guczSY sc-gBInCZ oxmqh" size="8">10 000.00 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 172px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjNTU4ZWFiIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICAzNAogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">34644641</p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">3 220.00 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 215px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjNDIxMDlhIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICAxNwogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">17080582</p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">2 655.24 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 258px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjNDdkMDJkIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICAxMAogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">10447216</p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">2 343.25 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 301px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjNDdkMDJkIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICAxMAogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">10447216</p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">2 343.25 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 344px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjMjRkMGFkIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICDQkNCRCiAgICAgICAgPC90ZXh0PgogICAgICA8L3N2Zz4KICAgIA==&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">Абдулхан</p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">1 700.00 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 387px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjN2JjNDAzIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICDQndCQCiAgICAgICAgPC90ZXh0PgogICAgICA8L3N2Zz4KICAgIA==&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">Наталья</p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">1 500.00 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 430px; width: 100%;">
                    <div class="sc-gfQywf llcVvG win-position">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjODMyNTI2IiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICAzOAogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">38851813</p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">1 500.00 ₽</div>
                      <div class="sc-dZTDFv cJmbDg">
                        <div class="sc-ilhmMj iKEoOT" type="low">1.70x</div>
                      </div>
                      <div class="sc-gelMue huegZp sc-hxOqGs osIUB" size="10">2 550.00 ₽</div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 473px; width: 100%;">
                    <div class="sc-gfQywf llcVvG win-position">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjMDc3Y2MwIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICAxOAogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">18742906</p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">1 150.92 ₽</div>
                      <div class="sc-dZTDFv cJmbDg">
                        <div class="sc-ilhmMj iKEoOT" type="low">1.62x</div>
                      </div>
                      <div class="sc-gelMue huegZp sc-hxOqGs osIUB" size="10">1 864.48 ₽</div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 516px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjYmUzYjVjIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICBEQQogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">Daniela </p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">1 101.57 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 559px; width: 100%;">
                    <div class="sc-gfQywf llcVvG win-position">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjOGUxZDYyIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICDQmtCYCiAgICAgICAgPC90ZXh0PgogICAgICA8L3N2Zz4KICAgIA==&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">Кирилл</p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">1 050.00 ₽</div>
                      <div class="sc-dZTDFv cJmbDg">
                        <div class="sc-ilhmMj iKEoOT" type="low">1.01x</div>
                      </div>
                      <div class="sc-gelMue huegZp sc-hxOqGs osIUB" size="10">1 060.50 ₽</div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 602px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjYTgyZjIwIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICDQnNCdCiAgICAgICAgPC90ZXh0PgogICAgICA8L3N2Zz4KICAgIA==&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">Мир Назаров</p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">1 000.00 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 645px; width: 100%;">
                    <div class="sc-gfQywf llcVvG win-position">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjZGM4NWJiIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICBBTgogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">Andrey</p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">1 000.00 ₽</div>
                      <div class="sc-dZTDFv cJmbDg">
                        <div class="sc-ilhmMj iKEoOT" type="low">1.03x</div>
                      </div>
                      <div class="sc-gelMue huegZp sc-hxOqGs osIUB" size="10">1 030.00 ₽</div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 688px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjYTU1OTgwIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICAzMgogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">3235622</p>
                      <div class="sc-gelMue huegZp sc-gBInCZ oxmqh" size="10">1 000.00 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 731px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjMWZiNDQ3IiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICBIQQogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">Hadi</p>
                      <div class="sc-gelMue huegYb sc-gBInCZ oxmqh" size="12">968.54 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 774px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjNWQyNmFlIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICAyNQogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">25704754</p>
                      <div class="sc-gelMue huegYb sc-gBInCZ oxmqh" size="12">781.08 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 817px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjYjk0OWI0IiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICBGRgogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">Fabianoferreirafontes Ferreira</p>
                      <div class="sc-gelMue huegYb sc-gBInCZ oxmqh" size="12">667.36 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                  <div style="height: 43px; left: 0px; position: absolute; top: 860px; width: 100%;">
                    <div class="sc-gfQywf cqBsdN">
                      <div class="sc-lknQiW kvpvwx sc-hlzHbZ dJrjTq" style="background-image: url(&quot;data:image/svg+xml;base64,CiAgICAgIDxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjNjNjNDIzIiAvPgogICAgICAgIDx0ZXh0IGZpbGw9IndoaXRlIiB4PSI1MHB4IiB5PSI2OHB4IiBzdHlsZT0iZm9udDogNTBweCBBcmlhbCwgc2Fucy1zZXJpZiIgdGV4dC1hbmNob3I9Im1pZGRsZSI+CiAgICAgICAgICAzMQogICAgICAgIDwvdGV4dD4KICAgICAgPC9zdmc+CiAgICA=&quot;);"></div>
                      <p class="sc-eVZGIO dvycLb">31965776</p>
                      <div class="sc-gelMue huegYb sc-gBInCZ oxmqh" size="12">600.00 ₽</div>
                      <div class="sc-hbWBzy dwifDg"></div>
                      <div class="sc-hbWBzy kMnoLx"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="resize-triggers">
              <div class="expand-trigger">
                <div style="width: 353px; height: 443px;"></div>
              </div>
              <div class="contract-trigger"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
