<div cdkDropList (cdkDropListDropped)="drop($event)">
  <div class="notification mb-2"
       [class.hero-bg]="message.from === 'hero'"
       [class.client-bg]="message.from === 'client'"
       *ngFor="let message of screenshot.messages; let i=index"
       cdkDrag>

    <button class="delete is-small" (click)="removeMessage(i)"></button>

    <div class="field">
      <div class="control">
        <textarea class="textarea is-small" [(ngModel)]="message.text" (mousedown)="$event.stopPropagation()" style="resize: vertical"></textarea>
      </div>
    </div>

    <div class="field is-grouped">

      <div class="control">
        <div class="field has-addons">
          <div class="control">
            <button class="button is-small" (click)="message.from='hero'" [ngClass]="{'is-primary is-selected':message.from==='hero'}">
              Герой
            </button>
          </div>
          <div class="control">
            <button class="button is-small" (click)="message.from='client'" [ngClass]="{'is-primary is-selected':message.from==='client'}">
              Клиент
            </button>
          </div>
        </div>
      </div>

      <div class="control">
        <input type="time"
               [ngModel]="message.time | date:'HH:mm'"
               (ngModelChange)="updateMessageTime($event, message)"
               class="input is-small"
               (mousedown)="$event.stopPropagation()" />
      </div>

      <div class="control" *ngIf="message.images.length">
        <div class="field has-addons">
          <div class="control">
            <button class="button is-small" (click)="message.imageOrientation='h'" [ngClass]="{'is-primary is-selected':message.imageOrientation==='h'}">
              <span class="icon is-small">
                <i class="fas fa-grip-horizontal"></i>
              </span>
            </button>
          </div>
          <div class="control">
            <button class="button is-small" (click)="message.imageOrientation='v'" [ngClass]="{'is-primary is-selected':message.imageOrientation==='v'}">
              <span class="icon is-small">
                <i class="fas fa-grip-vertical"></i>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="control">
        <button class="button is-small" (click)="translateMessage(message, 'EN-US')">
          <span class="icon is-small">
            <i class="fas fa-language"></i>
          </span>
          <span>EN</span>
        </button>
      </div>

      <div class="control">
        <button class="button is-small" (click)="translateMessage(message, 'ES')">
          <span class="icon is-small">
            <i class="fas fa-language"></i>
          </span>
          <span>ES</span>
        </button>
      </div>

      <div class="control">
        <button class="button is-small" (click)="translateMessage(message, 'PL')">
          <span class="icon is-small">
            <i class="fas fa-language"></i>
          </span>
          <span>PL</span>
        </button>
      </div>
    </div>

    <hr />

    <app-file-upload [files]="message.images"
                     [max]="2"
                     (addAviatorImageClick)="addAviatorImageClick.emit(message)"
                     (addLuckyJetImageClick)="addLuckyJetImageClick.emit(message)" />

  </div>

  <button class="button is-primary is-pulled-right" (click)="addMessage()">Добавить сообщение</button>

</div>
