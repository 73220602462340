import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TimelineService } from '../../services/timeline.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Timeline, TimelineView } from '../../models/telegram/timeline';

@Component({
  selector: 'app-timeline-list',
  templateUrl: './timeline-list.component.html',
  styleUrls: ['./timeline-list.component.css']
})
export class TimelineListComponent implements OnInit {

  timelines$: BehaviorSubject<Timeline[]> = new BehaviorSubject<Timeline[]>([]);
  searchQuery$ = new BehaviorSubject<string>('');
  newTimelineName: string = '';
  currentPage: number = 1;
  itemsPerPage: number = 20;
  isLoading: boolean = false;
  hasMore: boolean = true;
  selectedTimeline!: TimelineView;

  constructor(private timelineService: TimelineService) { }

  ngOnInit(): void {
    this.loadTimelines();

    this.searchQuery$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(query => {
          this.currentPage = 1;
          return this.timelineService.getTimelines(this.currentPage, this.itemsPerPage, query);
        })
      )
      .subscribe(response => {
        this.timelines$.next(response.items);
        this.hasMore = response.items.length >= this.itemsPerPage;
      });
  }

  loadTimelines() {
    if (this.isLoading /*|| !this.hasMore*/) return;

    this.isLoading = true;

    this.timelineService.getTimelines(this.currentPage, this.itemsPerPage, this.searchQuery$.getValue())
      .subscribe(response => {        
        const currentTimelines = this.timelines$.getValue();
        this.timelines$.next([...currentTimelines, ...response.items]);
        this.hasMore = response.items.length >= this.itemsPerPage;
        this.isLoading = false;
        this.currentPage++;
      });
  }

  onScroll() {
    this.loadTimelines();
  }

  addTimeline() {
    if (!this.newTimelineName.trim())
      return;

    this.timelineService.createTimeline(
      {
        id: '',
        name: this.newTimelineName
      })
      .subscribe(() => {
        this.newTimelineName = '';
        this.currentPage = 1;
        this.timelines$.next([]);
        this.loadTimelines();
      });
  }

  onSearch(event: any) {
    this.searchQuery$.next(event.target.value);
  }

  viewTimeline(id: string) {
    this.timelineService.getTimeline(id).subscribe(result => this.selectedTimeline = result);
  }
}
