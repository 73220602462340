import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TgMessage } from '../models/telegram/tg-message.model';
import { FileService } from '../services/file.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {

  constructor(private fileService: FileService) { }

  @Input()
  single: boolean = false;

  @Input()
  hide2buttons: boolean = false;

  @Input()
  max!: number;

  @Input()
  files!: (string | ArrayBuffer | null)[];

  @Output()
  filesUpdated = new EventEmitter<string[]>();

  @Output()
  addAviatorImageClick = new EventEmitter();

  @Output()
  addLuckyJetImageClick = new EventEmitter();

  onFileSelected(event: any): void {
    const formData = new FormData();
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    this.fileService.upload(formData)
      .subscribe((response: any) => {
        if (this.single) {
          this.files = [];
        }
        this.files.push(...response.urls);
        this.filesUpdated.emit(this.files as string []);
      });
  }

  removeImage(index: number) {
    this.files.splice(index, 1);
    this.filesUpdated.emit(this.files as string[]);
  }
}
