import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Template } from '../models/screen.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL')
    private baseUrl: string
  ) { }

  get(take: number): Observable<Template[]> {
    return this.http.get<Template[]>(this.baseUrl + 'api/templates?take=' + take);
  }
}
