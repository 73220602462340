<div class="columns is-centered">
  <div class="column is-one-third">
    <h1 class="title has-text-centered">Login</h1>
    <form>
      <div class="field">
        <label class="label">Логин</label>
        <div class="control">
          <input class="input" type="text" name="login" [(ngModel)]="credentials.username">
        </div>
      </div>

      <div class="field">
        <label class="label">Пароль</label>
        <div class="control">
          <input class="input" type="password" name="password" [(ngModel)]="credentials.password">
        </div>
      </div>

      <div class="field">
        <div class="control">
          <button class="button is-primary is-fullwidth" type="submit" (click)="login()">Войти</button>
        </div>
      </div>
    </form>
  </div>
</div>

