<div class="field is-grouped is-justify-content-end">
  <p class="control">
    <button class="button is-primary" (click)="openModal()">Создать клиента</button>
  </p>
</div>

<app-client-list (editClicked)="openModal($event)"
                 (removeClicked)="removeClient($event)"
                 [clients]="clients$ | async" />

<app-pager [totalItems]="totalItems"
           [itemsPerPage]="itemsPerPage"
           [currentPage]="currentPage"
           (pageChanged)="onPageChanged($event)">
</app-pager>

<app-modal [showModal]="showModal"
           (modalOpened)="showModal=$event"
           [title]="'Создать/редактировать клиента'"
           (saveClick)="saveClient()">

  <form class="form" *ngIf="selectedClient">

    <div class="field">
      <label class="label">Имя</label>
      <div class="control">
        <input class="input" type="text" name="name" [(ngModel)]="selectedClient.name">
      </div>
    </div>

    <div class="field">
      <label class="label">Пол</label>
      <div class="control">
        <div class="select">
          <select name="gender" [(ngModel)]="selectedClient.gender">
            <option value="male">Мужской</option>
            <option value="female">Женский</option>
          </select>
        </div>
      </div>
    </div>

    <div class="field">
      <label class="label">Регион</label>
      <div class="control">
        <div class="select">
          <select name="region" [(ngModel)]="selectedClient.region">
            <option value="slavic">Славяне</option>
            <option value="kaz">Казахстан</option>
            <option value="uzb">Узбекистан</option>
            <option value="kaz-eth">Казахстан этн.</option>
            <option value="uzb-eth">Узбекистан этн.</option>
          </select>
        </div>
      </div>
    </div>

    <div class="field">
      <label class="label">Язык</label>
      <div class="control">
        <div class="select">
          <select name="language" [(ngModel)]="selectedClient.language">
            <option value="rus">Русский</option>
            <option value="eng">Английский</option>
            <option value="kaz">Казахский</option>
            <option value="uzb">Узбекский</option>
          </select>
        </div>
      </div>
    </div>

    <div class="field">
      <label class="label">Аватар</label>
      <app-file-upload [files]="selectedClient.avatar ? [selectedClient.avatar] : []"
                       [max]="1"
                       [single]="true"
                       (filesUpdated)="selectedClient.avatar = $event[0]"
                       [hide2buttons]="true" />
    </div>

  </form>
</app-modal>
