import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {

  @Input()
  title!: string;

  @Input()
  hideButtons: boolean = false;

  @Input()
  isLoading: boolean = false;

  @Input()
  showModal: boolean = false;

  @Output()
  saveClick = new EventEmitter<void>();

  @Output()
  modalOpened = new EventEmitter<boolean>();

  closeModal() {
    this.showModal = false;
    this.modalOpened.emit(false);
  }

  save() {
    this.saveClick.emit();
  }
}
