import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private apiUrl = '/api/translation';

  constructor(private http: HttpClient) {}

  translate(text: string, targetLang: string): Observable<string> {
    return this.http.post<{ translatedText: string }>(this.apiUrl, { text, targetLang })
      .pipe(
        map(response => response.translatedText)
      );
  }
}