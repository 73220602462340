import { Component, Input } from '@angular/core';
import { TgSettings } from '../../models/telegram/tg-settings.model';

@Component({
  selector: 'app-screenshot-footer',
  templateUrl: './screenshot-footer.component.html',
  styleUrls: ['./screenshot-footer.component.css']
})
export class ScreenshotFooterComponent {
  @Input()
  settings!: TgSettings;
}
